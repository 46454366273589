import React from 'react';

import { formatSeconds } from '../../../utils';

interface Props {
  src: string;
  name: string;
  index: number;
  total_calls: number;
  total_duration: number;
  percent_calls: number;
  percent_duration: number;
  avg_duration: number;
}

const RelatorioEfetuadasItem: React.FC<Props> = (props) => {
  const { index, src, name, total_calls, total_duration, percent_calls, percent_duration, avg_duration } = props;

  return (
    <tr>
      <th>{index}</th>
      <td>
        <span className="text-muted mr-2">({src})</span>
        {name}
      </td>
      <td className="text-center">{total_calls}</td>
      <td className="text-center">{percent_calls} %</td>
      <td className="text-center">{formatSeconds(total_duration)}</td>
      <td className="text-center">{percent_duration} %</td>
      <td className="text-center">{formatSeconds(avg_duration)}</td>
    </tr>
  );
};

export default RelatorioEfetuadasItem;
