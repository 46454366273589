import React from 'react';

import Loading from '../Loading';
import { Container as SectionContainer, LoadingIndicatorContainer } from './styled';
import { useSmoothDisplay } from './utils';

interface Props {
  isLoading: boolean;
  container?: unknown;
  loadingComponent?: unknown;
}

const SectionLoading: React.FC<Props> = ({ isLoading, children, container, loadingComponent }) => {
  const loadingRef = useSmoothDisplay(isLoading, 300);

  const Container = container || SectionContainer;
  const Indicator = loadingComponent || LoadingIndicatorContainer;

  return (
    <Container>
      {children}
      <Indicator ref={loadingRef}>
        <Loading enabled={true} />
      </Indicator>
    </Container>
  );
};

export default SectionLoading;
