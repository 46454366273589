import React, { useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import { Form } from '@unform/web';

import api from '../../../services/api';
import { UnidadeContext } from '../../../services/context';
import ModalForm from './ModalForm';

interface Props {
  isOpen: boolean;
  initialData: User;
  onRefresh: () => void;
  toggle: () => void;
}

const FormCliente: React.FC<Props> = (props) => {
  const { isOpen, initialData, onRefresh, toggle } = props;
  const [loading, setLoading] = useState(false);
  const { unidade } = useContext(UnidadeContext);
  const action = initialData?.id ? 'update' : 'create';

  async function onSubmit(values: any) {
    setLoading(true);

    try {
      // Coloca o id do usuário no path caso seja update
      const path = action === 'update' ? '/' + initialData?.id : '';

      // Faz o request de POST para creae e PUT para update
      await api.request({
        url: `/unidade/${unidade?.id}/users${path}`,
        method: action === 'create' ? 'POST' : 'PUT',
        data: values,
      });

      toggle();
      onRefresh();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <Form onSubmit={onSubmit} initialData={initialData}>
        <ModalHeader toggle={toggle}>{action === 'create' ? 'Novo' : 'Editar'} usuário</ModalHeader>
        <ModalBody>
          <ModalForm />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={loading}>
            {loading && <Spinner size="sm" />}
            {loading ? ' Salvando...' : 'Salvar'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FormCliente;
