import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import FieldInput from '../../../components/FieldInput';
import FieldSwitch from '../../../components/FieldSwitch';
import SectionLoading from '../../../components/SectionLoading';
import vertibaApi from '../../../services/api';
import { Role } from '../../../utils';
import FieldSelectGroup from '../FieldSelectGroup';

const ModalForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    setLoading(true);

    vertibaApi
      .get('/roles')
      .then(({ data }) => setRoles(data.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SectionLoading isLoading={loading}>
      <FieldInput name="name" type="text" label="Nome de exibição" placeholder="Nome do Usuário" />
      <Row form>
        <Col>
          <FieldInput name="username" type="text" label="Usuário (login)" placeholder="username" />
        </Col>
        <Col>
          <FieldInput
            name="password"
            label="Senha (login)"
            type="password"
            placeholder="••••••"
            autocomplete="new-password"
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <FieldInput name="fop2_user" type="text" label="Usuário operador" placeholder="username" />
        </Col>
        <Col>
          <FieldInput name="fop2_pass" label="Senha operador" type="password" placeholder="••••••" />
        </Col>
      </Row>
      <FieldSelectGroup name="role" label="Grupo do usuário" roles={roles} />
      <FieldSwitch name="manager" label="Configurações" placeholder="O usuário é gerente" />
    </SectionLoading>
  );
};

export default ModalForm;
