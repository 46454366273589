import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import vertibaApi from '../../../services/api';
import { GroupPermissionContext, useSelectedRole } from '../utils';
import GroupPermissionTree from './GroupPermissionTree';
import {
  Container,
  GroupDescription,
  GroupName,
  GroupPermissionToolbar,
  ToolbarLeftSide,
  ToolbarRightSide,
} from './styles';

const UserGroupPermission: React.FC = () => {
  const { permissions, selected } = useContext(GroupPermissionContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const role = useSelectedRole();

  function cancel() {
    if (containerRef?.current) {
      containerRef.current.style.opacity = '0';
    }

    setTimeout(() => {
      history.push('/users/groups');
    }, 100);
  }

  function save() {
    setSaveLoading(true);

    const permissions = Object.entries(selected)
      .filter(([, selected]) => selected)
      .map(([item]) => parseInt(item.replace('perm_', '')));

    vertibaApi
      .put(`/roles/${role?.role_id}/permissions`, { permissions })
      .then(console.log)
      .finally(() => setSaveLoading(false));
  }

  return (
    <Container ref={containerRef}>
      <GroupPermissionToolbar>
        <ToolbarLeftSide>
          <GroupName>{role?.role_name}</GroupName>
          <GroupDescription>{role?.role_description}</GroupDescription>
        </ToolbarLeftSide>
        <ToolbarRightSide>
          <Button size="sm" onClick={cancel}>
            Cancelar
          </Button>
          <Button size="sm" color="primary" onClick={save} disabled={saveLoading}>
            Salvar
          </Button>
        </ToolbarRightSide>
      </GroupPermissionToolbar>
      <GroupPermissionTree permissions={permissions} />
    </Container>
  );
};

export default UserGroupPermission;
