import React from 'react';
import { Button, ButtonProps, Spinner } from 'reactstrap';

interface Props extends ButtonProps {
  label: string;
  loading: boolean;
  loadingLabel: string;
}

const ButtonLoading: React.FC<Props> = (props) => {
  const { loading, loadingLabel, label, ...rest } = props;
  return (
    <Button disabled={loading} {...rest}>
      {loading && <Spinner size="sm" />}
      {loading ? ' ' + loadingLabel : label}
    </Button>
  );
};

export default ButtonLoading;
