import { useLayoutEffect, useRef } from 'react';

export function useSmoothDisplay(show: boolean, time: number): React.RefObject<HTMLElement> {
  const componentRef = useRef<HTMLElement>(null);
  const loadRef = useRef<{ isLoading: boolean }>({ isLoading: false });

  function hideElement() {
    if (componentRef?.current) {
      componentRef.current.style.opacity = '0';
    }

    setTimeout(() => {
      if (componentRef?.current) {
        componentRef.current.style.display = 'none';
      }
    }, time);
  }

  function showElement() {
    if (componentRef?.current) {
      componentRef.current.style.display = 'block';
    }

    setTimeout(() => {
      if (componentRef?.current) {
        componentRef.current.style.opacity = '1';
      }
    }, 0);
  }

  function setLoading(loading: boolean) {
    if (loading !== loadRef.current.isLoading) {
      if (loading) {
        showElement();
      } else {
        hideElement();
      }

      loadRef.current.isLoading = loading;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => setLoading(show), [show]);

  useLayoutEffect(() => {
    if (componentRef?.current) {
      componentRef.current.style.transition = `opacity ${time}ms ease-out`;
    }
  }, [time]);

  return componentRef;
}
