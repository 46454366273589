import React, { useMemo } from 'react';
import { Button } from 'reactstrap';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatDateTime, formatPhone } from '../../../utils';

type Props = Contact;

const ContatosItem: React.FC<Props> = (props) => {
  const { ...contact } = props;

  const phone = useMemo(() => {
    if (contact.phone.length === 11) {
      return '55' + contact.phone;
    } else {
      return contact.phone;
    }
  }, [contact]);

  return (
    <tr>
      <th scope="row">{contact.id}</th>
      <td>{contact.name}</td>
      <td className="text-center">{formatPhone(contact.phone)}</td>
      <td>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            size="sm"
            color="link"
            className="py-0 px-1"
            tag="a"
            href={`https://wa.me/${phone}`}
            target="whatsapp"
          >
            <FontAwesomeIcon color="#06d755" icon={faWhatsapp} />
          </Button>
          +{phone}
        </div>
      </td>
      <td>{formatDateTime(contact.createdAt)}</td>
    </tr>
  );
};

export default ContatosItem;
