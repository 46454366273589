import React from 'react';
import { NavLinkProps as RNavLinkProps } from 'react-router-dom';
import { NavItem, NavLinkProps } from 'reactstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import RouteNavCollapse from '../RouteNavCollapse';
import RouteNavLink from '../RouteNavLink';

interface Props extends RNavLinkProps, Omit<NavLinkProps, 'onClick'> {
  icon?: IconProp;
  iconSrc?: string;
  hasPermission?: boolean;
}

const RouteNavItem: React.FC<Props> = (props) => {
  const { hasPermission = true, ...rest } = props;

  return hasPermission ? (
    <NavItem className="w-100">
      {props.children === undefined ? <RouteNavLink {...rest} /> : <RouteNavCollapse {...rest} />}
    </NavItem>
  ) : null;
};

export default RouteNavItem;
