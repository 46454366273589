import { HTMLAttributes } from 'react';

import styled from 'styled-components';

interface ContainerRowProps {
  full?: boolean;
  hasMenu?: boolean;
}

export const ContainerRow = styled.div.attrs({ className: 'row' })<ContainerRowProps>`
  height: 100%;

  .Menu__Container ~ & {
    margin-top: 54px;
    overflow-y: scroll;
  }

  &,
  main {
    ${(props) => (props.full ? 'flex-grow: 1!important' : 'flex-shrink: 1!important')};
  }
`;

interface ContentProps extends HTMLAttributes<HTMLDivElement> {
  hasMenu?: boolean;
  full?: boolean;
  centered?: boolean;
}

export const Content = styled.main<ContentProps>`
  width: 100%;
  padding: 15px;
  padding-top: 20px;
  margin: ${(props) => (props.centered ? 'auto 0' : 0)};
  position: relative;

  .Menu__Container ~ .row & {
    margin-top: ${(props) => (props.full ? 0 : '1rem')};
    margin-left: auto;
  }

  .Sidebar__Container ~ & {
    @media (min-width: 768px) {
      max-width: var(--content-width);
      flex: 0 0 var(--content-width);
    }
  }

  @media (min-width: 1360px) {
    padding-left: 115px;
    padding-right: 115px;
  }
`;

export const FooterContainer = styled.footer`
  width: 100%;
  padding: 1rem 0;
  margin-top: auto;
`;
