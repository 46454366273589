import React, { useContext, useEffect } from 'react';
import { Spinner } from 'reactstrap';

import { AuthUser } from '../../../services/auth';
import { UnidadeContext } from '../../../services/context';

const authUser = AuthUser.getInstance();

const IframeUser: React.FC = () => {
  const { unidadeUrl, loading } = useContext(UnidadeContext);

  useEffect(() => {
    const { fop2_user = '', fop2_pass = '' } = authUser.getUser();
    const url = `${unidadeUrl}/fop2/?exten=${fop2_user}&pass=${fop2_pass}`;
    const iframe = document.getElementById('fop2-iframe') as HTMLIFrameElement;

    if (!loading) {
      // Se a url for diferente da atual, muda ela
      if (iframe.src !== url) {
        iframe.src = url;
      }

      // Muda o iframe para visível
      iframe.style.opacity = '1';
      iframe.style.zIndex = '2';
    }

    return () => {
      // Ao fechar a página, muda o iframe para invisível
      iframe.style.opacity = '0';
      iframe.style.zIndex = '-1';
    };
  }, [loading, unidadeUrl]);

  return <Spinner style={{ color: '#6b71a2' }} />;
};

export default IframeUser;
