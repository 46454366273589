type Extensions = 'wav' | 'mp3';

export type CdrAudioExtensions = Extensions & string;

export default class CdrAudio {
  private _path?: string;
  private _name?: string;
  private _rename?: string;
  private _filename?: string;
  private _extension: Extensions = 'wav';
  private _unidadeUrl: string;

  constructor(unidadeUrl: string, path?: string) {
    this._path = path || '';
    this._filename = this._path.replace(/.*\//, '');
    this._name = this._filename.replace(/^(.*)\.\w+$/, '$1');
    this._extension = this._filename.replace(/^.*\.(\w+)$/, '$1') as Extensions;
    this._unidadeUrl = unidadeUrl;
  }

  /**
   * Rename the audio and returns this instance
   * @param value The value to prop set name
   * @returns instanceof CdrAudio
   */
  rename(value: string): CdrAudio {
    this._rename = value;
    return this;
  }

  extension(value: Extensions): CdrAudio {
    this._extension = value;
    return this;
  }

  get filename(): string {
    if (this._rename) {
      return `${this._rename}.${this._extension}`;
    }

    return this._filename || '';
  }

  get name(): string {
    if (this._rename) {
      return this._rename;
    }

    return this._name || '';
  }

  get url(): string {
    const name = encodeURIComponent(this.name);
    const path = encodeURIComponent(this._path || '');
    return `${this._unidadeUrl}/vertiba/api/recordingfile.php?path=${path}&name=${name}&convert=${this._extension}`;
  }

  get mimetype(): string {
    switch (this._extension) {
      case 'wav':
        return 'audio/wav';
      case 'mp3':
        return 'audio/mpeg';
      default:
        return 'audio/wav';
    }
  }
}
