import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Input, Row, Table } from 'reactstrap';

import Page from '../../components/Page';
import { UnidadeContext } from '../../services/context';
import { fetchWhatsappCampaigns } from '../../utils';
import FormCampanhaWhatsapp, { CampaignItem } from './FormCampanhaWhatsapp';

type CampaignList = CampaignItem[];

const CampanhaWhatsapp: React.FC = () => {
  const { loading: clientLoading } = useContext(UnidadeContext);
  const [campaigns, setCampaigns] = useState<CampaignList>([]);
  const [submitting, setSubmitting] = useState(false);
  const [formCampaign, setFormCampaign] = useState<Partial<CampaignItem>>();
  const [search, setSearch] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (!clientLoading) {
      loadCampaigns();
    }
  }, [clientLoading]);

  async function loadCampaigns() {
    const { data } = await fetchWhatsappCampaigns();
    setCampaigns(data.data);
  }

  function closeForm() {
    setFormCampaign(undefined);
  }

  function campaignFilter(campaign: CampaignItem) {
    const regexp = new RegExp(`.*${search}.*`, 'gi');
    return search === '' || regexp.test(campaign.name.toLowerCase());
  }

  async function onSubmit(values: Partial<CampaignItem>) {
    setSubmitting(true);

    try {
      // // Coloca o id do usuário no path caso seja update
      // const path = values.id ? '/' + values?.id : '';

      // // Faz o request de POST para creae e PUT para update
      // await api.request({
      //   url: `/client/${client?.id}/users${path}`,
      //   method: values.id ? 'PUT' : 'POST',
      //   data: values,
      // });

      if (!values.id) {
        setCampaigns((prev) => [...prev, { ...values, id: campaigns.length, active: false } as CampaignItem]);
      }

      closeForm();
      loadCampaigns();
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }

  function selectCampaign(id: number) {
    history.push(`/whatsapp-campaign/${id}`);
  }

  return (
    <Page title="Disparo whatsapp">
      <Row>
        <Col className="mb-3 mb-md-0">
          <Button className="mr-2" color="primary" size="sm" onClick={() => setFormCampaign({})}>
            Nova campanha
          </Button>
        </Col>
        <Col md={4}>
          <Input bsSize="sm" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
        </Col>
      </Row>
      <Table className="mt-5" size="sm" borderless responsive hover>
        <thead className="border-bottom">
          <tr>
            <th>Nome</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.filter(campaignFilter).map((campaign) => (
            <tr key={campaign.id} style={{ cursor: 'pointer' }} onClick={() => selectCampaign(campaign.id)}>
              <td>{campaign.name}</td>
              <td>{campaign.active ? 'Ativo' : 'Inativo'}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <FormCampanhaWhatsapp
        isOpen={!!formCampaign}
        submitting={submitting}
        onSubmit={onSubmit}
        initialData={formCampaign as CampaignItem}
        onClose={closeForm}
      />
    </Page>
  );
};

export default CampanhaWhatsapp;
