import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import { Form } from '@unform/web';

import ModalForm from './ModalForm';

export interface CampaignItem {
  id: number;
  name: string;
  active: boolean;
}

interface Props {
  isOpen: boolean;
  initialData: CampaignItem;
  submitting: boolean;
  // onRefresh: () => void;
  onClose: () => void;
  onSubmit: (data: CampaignItem) => Promise<void>;
}

const FormCampanhaWhatsapp: React.FC<Props> = (props) => {
  const { isOpen, initialData, submitting, onClose, onSubmit } = props;
  const action = initialData?.id ? 'update' : 'create';

  return (
    <Modal isOpen={isOpen} toggle={onClose} backdrop="static">
      <Form onSubmit={onSubmit} initialData={initialData}>
        <ModalHeader toggle={onClose}>{action === 'create' ? 'Nova' : 'Editar'} campanha</ModalHeader>
        <ModalBody>
          <ModalForm />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={submitting}>
            {submitting && <Spinner size="sm" />}
            {submitting ? ' Salvando...' : 'Salvar'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default FormCampanhaWhatsapp;
