import React from 'react';

import Page from '../../components/Page';

const NotFound: React.FC = () => {
  return (
    <Page>
      <h3 className="mb-3">Não encontrado</h3>
      <p>O link que você está tentando acessar não existe</p>
    </Page>
  );
};

export default NotFound;
