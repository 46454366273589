import React, { useContext, useMemo } from 'react';
import { Button } from 'reactstrap';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UnidadeContext } from '../../services/context';

interface Props {
  src: string;
  dst: string;
}

const WhatappTableButton: React.FC<Props> = (props) => {
  const { src, dst } = props;
  const { unidade } = useContext(UnidadeContext);

  // Verifica se é uma chamada externa
  const isExternal = src.length > 8 || dst.length > 8;

  // Formata o número do whatsapp
  const wppPhone = useMemo(() => {
    const phone = src.length < 8 ? dst : src;

    if (unidade?.ddd && (phone.length === 8 || phone.length === 9)) {
      return phone.replace(/^(\d{8,9})$/, `55${unidade.ddd}$1`);
    }

    return phone.replace(/^0?0?(\d{10,11})$/, '55$1');
  }, [src, dst, unidade]);

  return (
    <>
      {unidade?.ddd && isExternal ? (
        <Button
          size="sm"
          color="link"
          className="py-0 px-1"
          tag="a"
          href={`https://wa.me/${wppPhone}`}
          target="whatsapp"
        >
          <FontAwesomeIcon color="#06d755" icon={faWhatsapp} />
        </Button>
      ) : (
        '...'
      )}
    </>
  );
};

export default WhatappTableButton;
