import styled from 'styled-components';

export const PermissionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const PermissionCollapseButton = styled.div`
  text-align: center;
  margin-left: -1rem;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.3rem;
  cursor: pointer;
`;

export const PermissionGroup = styled.ul`
  padding-left: 0;
`;

export const PermissionItem = styled.li`
  list-style-type: none;

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0.1rem;
  }

  & > ul {
    padding-left: 0.7rem;
    margin-left: 0.3rem;
    border-left: thin solid rgba(0, 0, 0, 0.3);
  }
`;
