/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef } from 'react';

import moment from 'moment';

import PhoneNumber from './classes/PhoneNumber';
import vertibaApi, { serverApi, ServerPromiseResponse } from './services/api';

export class WebSocketKeepAlive {
  private static websocket: WebSocket;
  private static listeners: { type: string; listener: EventListener }[] = [];
  private status = false;
  private pending = false;

  constructor(private url: string) {
    this.createWebsocketInstance(this.url);
  }

  createWebsocketInstance(url: string) {
    this.pending = true;
    this.status = false;

    try {
      WebSocketKeepAlive.websocket = new WebSocket(url);

      WebSocketKeepAlive.websocket.onclose = () => {
        setTimeout(() => {
          this.createWebsocketInstance(url);
        }, 5000);
      };

      WebSocketKeepAlive.websocket.onopen = () => {
        this.status = true;
        this.pending = false;
      };

      for (const item of WebSocketKeepAlive.listeners) {
        WebSocketKeepAlive.websocket.addEventListener(item.type, item.listener);
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  listen(type: string, listener: EventListener) {
    WebSocketKeepAlive.listeners.push({ type, listener });
    WebSocketKeepAlive.websocket.addEventListener(type, listener);

    return () => {
      this.unlisten(type, listener);
    };
  }

  unlisten(type: string, listener: EventListener) {
    WebSocketKeepAlive.listeners = WebSocketKeepAlive.listeners.filter((item) => {
      return type !== item.type && item.listener !== listener;
    });

    WebSocketKeepAlive.websocket.removeEventListener(type, listener);
  }

  send(data: string) {
    WebSocketKeepAlive.websocket.send(data);
  }
}

export function useWebsocket(url: string) {
  const socket = useRef<WebSocketKeepAlive>();

  useEffect(() => {
    if (url) {
      socket.current = new WebSocketKeepAlive(url);
    }
  }, [url]);

  return socket.current;
}

export function getUnidadeUrl(domain: string): Promise<string> {
  const localUrl = `https://local.${domain}`;
  const remoteUrl = `https://painel.${domain}:8443`;

  return new Promise((resolve) => {
    let hasError = false;

    function catchToLocal() {
      if (hasError) {
        resolve(localUrl);
      } else {
        hasError = true;
      }
    }

    serverApi
      .get(localUrl + '/vertiba/status.php')
      .then(() => resolve(localUrl))
      .catch(catchToLocal);

    serverApi
      .get(remoteUrl + '/vertiba/status.php')
      .then(() => resolve(remoteUrl))
      .catch(catchToLocal);
  });
}

export function fetchWhatsappCampaigns() {
  return serverApi.get('/vertiba/api/all-campaigns.list.php');
}

export function fetchCalls(filter: CallsPagingFilter, user: User) {
  const params = fetchCallsParams(filter, user);
  return serverApi.get('/vertiba/api/all-calls.list.php', { params });
}

export function fetchFop2Recs(filter: CallsPagingFilter, user: User) {
  const params = fetchCallsParams(filter, user);
  return serverApi.get('/vertiba/api/fop2recs.list.php', { params });
}

export function fetchLostCalls(filter: CallsPagingFilter) {
  const params = fetchCallsParams(filter);
  return serverApi.get('/vertiba/api/lost-calls.list.php', { params });
}

export function fetchUsers(unidade: Unidade) {
  return vertibaApi.get(`/unidade/${unidade?.id}/users`);
}

export function submitFop2Users(unidade: Unidade, data: unknown) {
  return vertibaApi.post(`/unidade/${unidade?.id}/fop2/sync-users`, {
    users: data,
  });
}

export function fetchFop2Users() {
  return serverApi.get('/vertiba/api/fop2users.php');
}

export function fetchCallCenterContacts(client: Client) {
  return vertibaApi.get(`/callcenter/${client?.id}/contacts`);
}

export function fetchWhatsappContacts(id_campaign: number | string): ServerPromiseResponse<Contact[]> {
  return serverApi.get('/vertiba/api/get-campaign-contacts.php', {
    params: { id_campaign },
  });
}

export function fetchCallsParams(filter: CallsPagingFilter, user?: User): CallsQueryParams {
  // Create params
  const params: CallsQueryParams = filter;

  // Add user to params if not is manager
  if (user && !user.manager) {
    params.user = user.fop2_user;
  }

  return params;
}

/**
 * Fix the Fop2 audio path
 * @param rec Fop2Recording object
 */
export function fix_fop2_audio_path(rec?: Fop2Recording): string | undefined {
  if (rec && rec.filename && !~rec.filename.search('/')) {
    const date = moment(rec.datetime).format('YYYY/MM/DD');
    return `/var/spool/asterisk/monitor/${date}/${rec.filename}`;
  }

  return rec?.filename;
}

/**
 * Fix the Call audio path
 * @param rec Fop2Recording object
 */
export function fix_call_audio_path(rec?: Call): string | undefined {
  if (rec && rec.audio && !~rec.audio.search('/')) {
    const date = moment(rec.date).format('YYYY/MM/DD');
    return `/var/spool/asterisk/monitor/${date}/${rec.audio}`;
  }

  return rec?.audio;
}

export function formatPhone(phone: string): string {
  if (!phone) {
    return '';
  } else if (phone === 's') {
    return 'ura';
  } else {
    return new PhoneNumber(phone).format();
  }
}

export function formatDateTime(date: string): string {
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export function formatSeconds(secs: number | string | undefined): string {
  const day = moment().startOf('day');

  if (secs) {
    if (typeof secs === 'string') {
      return day.seconds(parseInt(secs)).format('HH:mm:ss');
    } else {
      return day.seconds(secs).format('HH:mm:ss');
    }
  }

  return '...';
}

export function formatDisposition(d: CallDisposition): string {
  const DISPOSITIONS = {
    BUSY: 'Ocupado',
    FAILED: 'Falhou',
    ANSWERED: 'Atendida',
    'NO ANSWER': 'Não atendida',
  };

  return DISPOSITIONS[d] || d;
}

export interface Role {
  role_id: number;
  role_name: string;
  role_description: string;
  permissions: PermissionList;
}

export interface PermissionListItem {
  perm_id: number;
  perm_parent: number;
  perm_desc: string;
  perm_key: string;
}

export interface PermissionTreeItem {
  perm_id: number;
  perm_desc: string;
  perm_key: string;
  items?: PermissionTree;
}

export type PermissionList = PermissionListItem[];
export type PermissionTree = PermissionTreeItem[];

/**
 * Converts a linear permission list to a permission tree
 * @param list Linear list of permissions with perm_parent field
 * @param parent Actual parent of list section
 */
export function permissionList2tree(list: PermissionList, parent = 0): PermissionTree {
  // Get only current parent elements
  const items = list.filter((item) => item.perm_parent === parent);
  // Get another elements
  const other = list.filter((item) => item.perm_parent !== parent);

  // Converts all items to tree format recursive
  return items.map((item) => ({
    perm_id: item.perm_id,
    perm_desc: item.perm_desc,
    perm_key: item.perm_key,
    items: permissionList2tree(other, item.perm_id),
  }));
}
