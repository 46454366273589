import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { Container } from './styles';

interface Props {
  path?: string;
}

const ButtonBack: React.FC<Props> = (props) => {
  const history = useHistory();

  function goBack() {
    if (props.path) {
      history.replace(props.path);
    } else {
      history.goBack();
    }
  }

  return (
    <Button size="sm" color="link" style={{ color: '#000' }} onClick={goBack}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </Button>
  );
};

export default ButtonBack;
