import React, { useState } from 'react';

import Page from '../../components/Page';
import FormRelatorioEfetuadas, { RelatorioEfetuadasFields } from './FormRelatorioEfetuadas';
import ListRelatorioEfetuadas from './ListRelatorioEfetuadas';
// import { serverApi } from "../../../services/api";
// import { UnidadeContext } from "../../../services/context";
// import { ToolbarRightButtonContainer } from "./styles";

const RelatorioEfetuadas: React.FC = () => {
  const [query, setQuery] = useState<RelatorioEfetuadasFields | undefined>();

  function fetchReport(values: RelatorioEfetuadasFields) {
    setQuery(values);
  }

  return (
    <Page title="Chamadas efetuadas">
      {!query ? (
        <FormRelatorioEfetuadas onSubmit={fetchReport} />
      ) : (
        <ListRelatorioEfetuadas query={query} onNewQuery={() => setQuery(undefined)} />
      )}
    </Page>
  );
};

export default RelatorioEfetuadas;
