import React, { forwardRef } from 'react';

import { Container, LoadingContent, LoadingLogo, LoadingSpinner } from './styles';

const PageLoading: React.RefForwardingComponent<HTMLDivElement> = (props, ref) => {
  return (
    <Container ref={ref}>
      <LoadingContent>
        <LoadingLogo src={require('../../assets/logo-vertiba.png')} />
        <LoadingSpinner />
      </LoadingContent>
    </Container>
  );
};

export default forwardRef(PageLoading);
