import React from 'react';

import { faLevelDownAlt, faLevelUpAlt, faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

import { ListPagesItem } from '../../components/ListPages';
import { ListPagesContainer } from '../../components/ListPages/styles';
import Page from '../../components/Page';

const Relatorios: React.FC = () => {
  return (
    <Page>
      <h3>Relatórios</h3>
      <ListPagesContainer>
        <ListPagesItem to="/report/all-calls" label="Todas as ligações" permission="report-all-calls" icon={faPhone} />
        <ListPagesItem
          to="/report/lost-calls"
          label="Ligações perdidas"
          permission="report-lost-calls"
          icon={faPhoneSlash}
        />
        <ListPagesItem
          to="/report/outgoing"
          label="Chamadas efetuadas"
          permission="report-outgoing"
          icon={faLevelUpAlt}
        />
        <ListPagesItem
          to="/report/incoming"
          label="Chamadas recebidas"
          permission="report-incoming"
          icon={faLevelDownAlt}
        />
      </ListPagesContainer>
    </Page>
  );
};

export default Relatorios;
