import { Spinner } from 'reactstrap';

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1040;
  background-color: white;

  @media screen and (max-width: 768px) {
    background-color: black;
  }
`;

export const LoadingContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingLogo = styled.img`
  margin-bottom: 24px;
  max-width: 60vw;

  @media (min-width: 768px) {
    max-width: 280px;
  }
`;

export const LoadingSpinner = styled(Spinner)`
  color: var(--color-primary);
`;
