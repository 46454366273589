import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import moment from 'moment';

import CdrAudio from '../../classes/CdrAudio';
import CallsFormFilter from '../../components/CallsFormFilter';
import DownloadAudioModal from '../../components/DownloadAudioModal';
import Page from '../../components/Page';
import SectionLoading from '../../components/SectionLoading';
import SmartPagination from '../../components/SmartPagination';
import { getUser } from '../../services/auth';
import { UnidadeContext } from '../../services/context';
import { fetchFop2Recs, fix_fop2_audio_path } from '../../utils';
import CallsItem from './CallsItem';

interface Fop2RecsResult {
  data: Fop2Recordings;
  count: number;
}

const CallRecsFop2: React.FC = () => {
  const { loading: clientLoading, unidadeUrl } = useContext(UnidadeContext);
  const user = getUser();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Fop2RecsResult>({ count: 0, data: [] });
  const [callListen, setCallListen] = useState<Fop2Recording>();
  const [filter, setFilter] = useState<CallsPagingFilter>({
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    filter: 'src',
    search: '',
    page: 1,
  });

  const offset = (filter.page - 1) * 25 + 1;
  const pageLoading = clientLoading || loading;

  const loadCalls = useCallback(
    (filter: CallsPagingFilter) => {
      setLoading(true);
      setResult((result) => ({ ...result, data: [] }));

      fetchFop2Recs(filter, user)
        .then(({ data }) => setResult(data))
        .finally(() => setLoading(false));
    },
    [user],
  );

  // TODO: salvar a página junto do filtro
  function onChangeFilter(filter: CallsFilter) {
    setFilter({ ...filter, page: 1 });
  }

  useEffect(() => {
    clientLoading || loadCalls(filter);
    // eslint-disable-next-line
  }, [clientLoading, filter]);

  return (
    <Page className="bg-white" title="Gravações do FOP2">
      <CallsFormFilter filter={filter} onChangeFilter={onChangeFilter} />
      <SectionLoading isLoading={pageLoading}>
        <Table className="mt-3" size="sm" borderless responsive hover>
          <thead className="border-bottom">
            <tr>
              <th>#</th>
              <th>Ramal</th>
              <th>Destino</th>
              <th>Duração</th>
              <th>Data</th>
              <th className="text-center">WhatsApp</th>
              <th className="text-center">Gravação</th>
            </tr>
          </thead>
          <tbody>
            {result.data?.map((call, index) => (
              <CallsItem
                {...call}
                key={`${call.id}.${call.targetextension}`}
                index={offset + index}
                // filename={fix_audio_url(call)}
                onPlayAudio={() => {
                  setCallListen(call);
                }}
              />
            ))}
          </tbody>
        </Table>
        <SmartPagination
          page={filter.page}
          total={result.count}
          pageLength={result.data.length}
          onChangePage={(page) => setFilter((prev) => ({ ...prev, page }))}
        />
      </SectionLoading>
      <DownloadAudioModal
        isOpen={!!callListen}
        extension="mp3"
        cdrAudio={new CdrAudio(unidadeUrl, fix_fop2_audio_path(callListen))}
        onClose={() => setCallListen(undefined)}
      />
    </Page>
  );
};

export default CallRecsFop2;
