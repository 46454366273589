import React, { useCallback, useEffect, useState } from 'react';
import { Button, CustomInput, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import isEqual from 'lodash/isEqual';

import { formatPhone } from '../../utils';
import DownloadDropdown from './DownloadDropdown';
import { Container, FilterContainer, FilterRadioContainer, FilterSearchContainer, LeftSide, RightSide } from './styles';

interface Props {
  filter: CallsFilter;
  onChangeFilter: (filter: CallsFilter) => void;
}

const CallsFormFilter: React.FC<Props> = (props) => {
  const { filter, onChangeFilter } = props;
  const [startDate, setStartDate] = useState(filter.start_date);
  const [endDate, setEndDate] = useState(filter.end_date);
  const [radio, setRadio] = useState<'src' | 'dst'>('src');
  const [search, setSearch] = useState('');

  const execFilter = useCallback(
    (value: string) => {
      const params = {
        start_date: startDate,
        end_date: endDate,
        filter: radio,
        search: value.replace(/\D/g, ''),
      };

      if (!isEqual(filter, params)) {
        onChangeFilter(params);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [radio, startDate, endDate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => execFilter(search), [execFilter]);

  return (
    <Container>
      <LeftSide>
        <FormGroup>
          <Label>Data inicial</Label>
          <Input bsSize="sm" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label>Data final</Label>
          <Input bsSize="sm" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </FormGroup>
      </LeftSide>
      <RightSide>
        <FilterContainer>
          <FilterRadioContainer>
            <CustomInput
              className="mr-2"
              type="radio"
              id="filter-caller"
              name="filter"
              defaultChecked={true}
              label="Chamador"
              onClick={() => setRadio('src')}
              inline
            />
            <CustomInput
              className="mr-2"
              type="radio"
              id="filter-receiver"
              name="filter"
              label="Destino"
              onClick={() => setRadio('dst')}
              inline
            />
          </FilterRadioContainer>
          <FilterSearchContainer>
            <InputGroup size="sm">
              <Input
                placeholder="Digite o número"
                value={formatPhone(search)}
                onChange={(e) => setSearch(e.target.value.replace(/\D/g, ''))}
                onKeyPress={(e) => e.key === 'Enter' && execFilter(e.currentTarget.value)}
              />
              <InputGroupAddon addonType="append">
                <Button color="secondary" onClick={() => execFilter(search)}>
                  Buscar
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FilterSearchContainer>
        </FilterContainer>
        <DownloadDropdown search={search.replace(/\D/g, '')} filter={radio} start_date={startDate} end_date={endDate} />
      </RightSide>
    </Container>
  );
};

export default CallsFormFilter;
