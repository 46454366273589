import React, { useEffect, useRef } from 'react';
import { FormGroup, Input, InputProps, Label } from 'reactstrap';

import { useField } from '@unform/core';

interface Props extends InputProps {
  name: string;
  label?: string;
}

const FieldInput: React.FC<Props> = (props) => {
  const { name, label, ...rest } = props;
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormGroup>
      {label && <Label for={fieldName}>{label}</Label>}
      <Input innerRef={inputRef} id={fieldName} defaultValue={defaultValue} {...rest} />
      {error && <span>{error}</span>}
    </FormGroup>
  );
};

export default FieldInput;
