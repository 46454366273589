import React, { useState } from 'react';
import { Button, CustomInput, FormGroup, Label } from 'reactstrap';

import { serverApi } from '../../../services/api';
import { CampaignItem } from '../utils';
import { CampanhaMailingContainer, CampanhaMailingForm } from './styles';

interface Props {
  campanha: CampaignItem;
}

const CampanhaMailing: React.FC<Props> = ({ campanha }) => {
  const [fileName, setFileName] = useState<string>();

  async function submitMailing() {
    const file = document.getElementById('mailing-file') as HTMLInputElement;
    const type = document.getElementById('mailing-type') as HTMLInputElement;

    if (file.files && file.files.length) {
      const formData = new FormData();
      formData.append('mailing-file', file.files?.[0] as Blob);
      formData.append('mailing-type', type.value);
      formData.append('id_campaign', campanha?.id as string);

      const { data } = await serverApi.post('/vertiba/api/submit-campaign-contacts.php', formData);
      console.log(data);
    }
  }

  return (
    <CampanhaMailingContainer>
      <h5 className="mb-3">Importar mailing</h5>
      <CampanhaMailingForm>
        <FormGroup>
          <Label for="mailing-type">Tipo</Label>
          <CustomInput bsSize="sm" type="select" id="mailing-type" name="mailing-type">
            <option value="csv-comma">CSV</option>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="mailing-file">Arquivo de mailing</Label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="mailing-file"
              name="mailing-file"
              onChange={(e) => {
                if (e.target.files?.length) {
                  setFileName(e.target.files[0].name);
                }
              }}
            />
            <label className="custom-file-label" htmlFor="mailing-file">
              {fileName || 'Selecione um arquivo'}
            </label>
          </div>
        </FormGroup>
      </CampanhaMailingForm>
      <Button block onClick={submitMailing}>
        Importar
      </Button>
    </CampanhaMailingContainer>
  );
};

export default CampanhaMailing;
