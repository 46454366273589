import styled from 'styled-components';

export const Container = styled.div`
  --button-size: 74px;
  --button-margin-y: 6px;
  --button-margin-x: 14px;
  --button-margin: var(--button-margin-y) var(--button-margin-x);
  --button-label-width: calc(var(--button-size) - 42px);
  --button-horizontal-size: calc(var(--button-size) + (var(--button-margin-x) * 2));
  --phone-width: calc(var(--button-horizontal-size) * 3);

  user-select: none;
  -webkit-touch-callout: none;

  transition: background-color 0.3s;
  overflow: hidden;
`;

export const PhoneButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: var(--phone-width);
  margin: auto;
`;

export const PhoneButtonContainer = styled.div`
  flex: 1 0 33%;
  justify-content: center;
  display: flex;
`;

export const PhoneButtonContent = styled.div`
  flex: 1;
  display: flex;
  margin: var(--button-margin);
  background-color: #6b71a2;
  color: #fff;
  max-height: var(--button-size);
  max-width: var(--button-size);
  height: var(--button-size);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  transition: opacity 0.3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &:active,
  &:focus,
  &:active {
    opacity: 0.6;
  }
`;

export const PhoneButtonDigit = styled.div`
  font-size: var(--button-label-width);
  line-height: var(--button-label-width);
`;

export const PhoneButtonLabel = styled.div`
  line-height: 12px;
  font-size: 12px;
`;
