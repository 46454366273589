import React, { useMemo, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
  totalRows: number;
  defaultPage?: number;
  onChangePage: (page: number) => void;
}

const PaginationButtons: React.FC<Props> = (props) => {
  const { defaultPage, totalRows, onChangePage } = props;
  const [page, setPage] = useState(defaultPage || 1);
  const totalPages = Math.ceil(totalRows / 25) || 1;

  const pages = useMemo(() => {
    if (totalPages > 7) {
      const last = Array.from(Array(3)).map((v, i) => totalPages - (7 - i - 5));

      if (page < 3 || page > totalPages - 2) {
        return [1, 2, 3, '...', ...last];
      } else if (page === 3) {
        last.shift();
        return [1, 2, 3, 4, '...', ...last];
      } else if (page === totalPages - 2) {
        return [1, 2, '...', page - 1, ...last];
      } else {
        return [1, '...', page - 1, page, page + 1, '...', totalPages];
      }
    } else {
      return Array.from(Array(totalPages)).map((v, i) => i + 1);
    }
  }, [page, totalPages]);

  function updatePage(p: number) {
    onChangePage(p);
    setPage(p);
  }

  function previous() {
    setPage((p) => {
      onChangePage(p - 1);
      return p - 1;
    });
  }
  function next() {
    setPage((p) => {
      onChangePage(p + 1);
      return p + 1;
    });
  }

  return (
    <Pagination className="d-flex justify-content-center" aria-label="Page navigation">
      <PaginationItem disabled={page === 1}>
        <PaginationLink previous onClick={previous}>
          {'<'}
        </PaginationLink>
      </PaginationItem>
      {pages.map((item, index) => (
        <PaginationItem key={`${index}.${item}`} active={page === item} disabled={item === '...'}>
          <PaginationLink onClick={() => updatePage(item as number)}>{item}</PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={totalPages === page}>
        <PaginationLink next onClick={next}>
          {'>'}
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationButtons;
