import styled from 'styled-components';

// export const Container = styled.div``;

export const CampanhaMailingContainer = styled.div`
  margin-top: 24px;
  padding: 12px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dee2e6;
`;

export const CampanhaMailingForm = styled.div`
  display: flex;

  .form-group:first-child {
    margin-right: 12px;
    min-width: 100px;
  }

  .custom-file-label,
  .custom-file-label::after {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    height: initial;
  }

  .custom-file-label::after {
    content: 'Buscar';
  }
`;
