import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  label?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { indeterminate = false, label, ...rest } = props;
  return (
    <div className="custom-control custom-checkbox">
      <input
        ref={(elem) => elem && (elem.indeterminate = indeterminate)}
        type="checkbox"
        className="custom-control-input"
        {...rest}
      />
      {/* {label && ( */}
      <label className="custom-control-label" htmlFor={rest.id}>
        {label}
      </label>
      {/* )} */}
    </div>
  );
};

export default Checkbox;
