import { useMemo } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHeadset, faMobileAlt, faPhoneAlt, faVoicemail } from '@fortawesome/free-solid-svg-icons';

import PhoneNumber from '../../classes/PhoneNumber';

const types: { [key: string]: { title: string; icon: IconProp } } = {
  ura: {
    title: 'Assistente virtual',
    icon: faVoicemail,
  },
  extension: {
    title: 'Ramal de atendente',
    icon: faHeadset,
  },
  landline: {
    title: 'Telefone fixo',
    icon: faPhoneAlt,
  },
  mobile: {
    title: 'Celular',
    icon: faMobileAlt,
  },
};

export function usePhoneDetails(number: string) {
  const data = useMemo(() => {
    // PhoneNumber object
    const phone = new PhoneNumber(number);

    if (number === 's') {
      return types.ura; // Ligação que parou na ura
    } else if (number.length < 8) {
      return types.extension; // Ligação de ramal
    } else if (phone.is('landline')) {
      return types.landline; // Ligação de fixo
    } else if (phone.is('mobile')) {
      return types.mobile; // Ligação de celular
    }

    return types.landline;
  }, [number]);

  return data;
}
