import React, { useContext, useState } from 'react';
import { Button, CustomInput, Spinner } from 'reactstrap';

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api from '../../../services/api';
import { UnidadeContext } from '../../../services/context';
import { formatDateTime } from '../../../utils';
import { ActionContainer, LoadingActionContainer } from './styles';

interface Props extends User {
  onRefresh: () => void;
  onClickEdit: () => void;
}

const UsuariosItem: React.FC<Props> = (props) => {
  const { onRefresh, onClickEdit, ...user } = props;
  const { unidade } = useContext(UnidadeContext);
  const [loading, setLoading] = useState(false);

  function onChangeStatus(e: React.ChangeEvent<HTMLInputElement>) {
    const active = e.target.checked;
    api.put(`/unidade/${unidade?.id}/users/${user.id}`, { active }).then(() => {
      onRefresh?.();
    });
  }

  async function deleteUser() {
    setLoading(true);

    try {
      if (window.confirm(`Deseja realmente excluir o usuário "${user.name}"`)) {
        await api.delete(`/unidade/${unidade?.id}/users/${user.id}`);
        onRefresh();
      }
    } catch (error) {
      alert('Ocorreu um erro ao excluir o usuário!');
    } finally {
      setLoading(false);
    }
  }

  return (
    <tr>
      <th scope="row">{user.id}</th>
      <td>{user.active ? user.name : <s>{user.name}</s>}</td>
      <td>{user.username}</td>
      <td>{user.manager ? 'Sim' : 'Não'}</td>
      <td>{user.fop2_user}</td>
      <td>{formatDateTime(user.createdAt)}</td>
      <td className="p-0" style={{ verticalAlign: 'center' }}>
        {loading ? (
          <LoadingActionContainer>
            <Spinner size="sm" />
          </LoadingActionContainer>
        ) : (
          <ActionContainer>
            <CustomInput
              type="switch"
              color="secondary"
              id={'status_' + user.id}
              name={'status_' + user.id}
              checked={user.active}
              onChange={onChangeStatus}
            />
            <Button size="sm" color="link" className="py-0 px-1" onClick={onClickEdit}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button size="sm" color="link" className="py-0 px-1 text-danger" onClick={deleteUser}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </ActionContainer>
        )}
      </td>
    </tr>
  );
};

export default UsuariosItem;
