import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthUser } from '../../services/auth';
import { ListPagesItemContainer, ListPagesItemLabel } from './styles';

const user = AuthUser.getInstance();

export interface ListPagesItemProps {
  to: string;
  label: string;
  icon?: IconProp;
  renderIcon?: React.ReactNode;
  permission?: string;
}

const ListPages: React.FC = () => {
  return <div />;
};

export const ListPagesItem: React.FC<ListPagesItemProps> = (props) => {
  const { icon, permission, label, to, renderIcon } = props;
  const history = useHistory();

  if (permission && !user.hasPermission(permission)) {
    return null;
  }

  function goToPage() {
    history.push(to);
  }

  return (
    <ListPagesItemContainer onClick={goToPage}>
      {renderIcon}
      {icon && <FontAwesomeIcon style={{ width: 16 }} icon={icon} />}
      <ListPagesItemLabel>{label}</ListPagesItemLabel>
      <FontAwesomeIcon style={{ height: 10, color: '#9c9c9c' }} icon={faChevronRight} />
    </ListPagesItemContainer>
  );
};

export default ListPages;
