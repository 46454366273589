import styled, { createGlobalStyle } from 'styled-components';

export const SidebarGlobal = createGlobalStyle`
  @media (min-width: 768px) {
    .sidebar-offset {
      max-width: calc(100% - 210px);
      flex: 0 0 calc(100% - 210px);
    }
  }
`;

export const Container = styled.div``;

export const SidebarBackdrop = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.35s;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({ isOpen }) => (isOpen ? 11 : 0)};
  opacity: 0;
`;

export const SidebarCollapse = styled.div`
  width: 210px;

  @media (max-width: 768px) {
    width: 240px;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: left;
    left: ${({ isOpen }) => (isOpen ? 0 : '-240px')};
  }
`;
