import React, { useContext, useEffect, useMemo, useState } from 'react';

import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkbox from '../../../../components/Checkbox';
import { PermissionTree, PermissionTreeItem } from '../../../../utils';
import { GroupPermissionContext } from '../../utils';
import { PermissionCollapseButton, PermissionGroup, PermissionItem, PermissionLabelContainer } from './styles';

interface Props {
  permissions: PermissionTree;
}

const GroupPermissionItem: React.FC<PermissionTreeItem> = (perm) => {
  const { selected, setPermission } = useContext(GroupPermissionContext);
  const [collapsed, setCollapsed] = useState(true);
  const permissionKey = `perm_${perm.perm_id}`;
  const itemsLength = perm.items?.length || 0;
  const hasItems = !!itemsLength;

  const checkedChildren = useMemo(() => {
    const selectedItems = perm.items?.filter((item) => {
      return !!selected[`perm_${item.perm_id}`];
    });

    return selectedItems?.length || 0;
  }, [selected, perm.items]);

  const isChecked = checkedChildren > 0 && checkedChildren === itemsLength;
  const isIndeterminate = !isChecked && checkedChildren > 0;

  useEffect(() => {
    const checked = isChecked || isIndeterminate;

    if (hasItems && selected[permissionKey] !== checked) {
      setPermission(permissionKey, checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndeterminate]);

  function toogleCollapse() {
    setCollapsed((prev) => !prev);
  }

  return (
    <PermissionItem>
      <PermissionLabelContainer>
        {hasItems && (
          <PermissionCollapseButton onClick={toogleCollapse}>
            <FontAwesomeIcon color="#333" icon={collapsed ? faChevronRight : faChevronDown} />
          </PermissionCollapseButton>
        )}
        <Checkbox
          id={permissionKey}
          label={perm.perm_desc}
          disabled={hasItems}
          checked={selected[permissionKey]}
          indeterminate={isIndeterminate}
          // tslint:disable-next-line: jsx-no-lambda
          onChange={(e) => setPermission(permissionKey, e.target.checked)}
        />
      </PermissionLabelContainer>
      {perm.items && !collapsed && <GroupPermissionTree permissions={perm.items} />}
    </PermissionItem>
  );
};

const GroupPermissionTree: React.FC<Props> = (props) => {
  return (
    <PermissionGroup>
      {props.permissions.map((perm) => (
        <GroupPermissionItem key={`perm_${perm.perm_id}`} {...perm} />
      ))}
    </PermissionGroup>
  );
};

export default GroupPermissionTree;
