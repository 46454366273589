import React, { useContext } from 'react';

import PhoneTableCol from '../../../components/PhoneTableCol';
import WhatappTableButton from '../../../components/WhatappTableButton';
import { UnidadeContext } from '../../../services/context';
import { formatDateTime, formatDisposition, formatSeconds } from '../../../utils';
import { TableRow } from './styles';

interface Props extends Call {
  index: number;
  onPlayAudio: (url: string) => void;
}

const CallsItem: React.FC<Props> = (props) => {
  const { onPlayAudio, billsec, index } = props;
  const { unidadeUrl } = useContext(UnidadeContext);

  function playAudio(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    onPlayAudio(`${unidadeUrl}/vertiba-api/recordingfile.php?path=${props.audio}`);
  }

  return (
    <TableRow>
      <td>
        <PhoneTableCol id={'src_' + index} phone={props.src} />
      </td>
      <td>
        <PhoneTableCol id={'dst_' + index} phone={props.dst} />
      </td>
      <td className="text-center">{props.src.length < 8 ? 'Efetuada' : 'Recebida'}</td>
      <td className="text-center">{formatDisposition(props.disposition)}</td>
      <td className="text-center">{formatSeconds(billsec)}</td>
      <td>{formatDateTime(props.date)}</td>
      <td className="text-center">
        <WhatappTableButton src={props.src} dst={props.dst} />
      </td>
      <th className="text-center">
        {props.audio ? (
          <a
            href={`${unidadeUrl}/vertiba-api/recordingfile.php?path=${props.audio}`}
            onClick={playAudio}
            title={props.audio}
            target="_blank"
            rel="noopener noreferrer"
          >
            ouvir
          </a>
        ) : (
          '...'
        )}
      </th>
    </TableRow>
  );
};

export default CallsItem;
