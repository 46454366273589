import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ColumnLayout = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  flex: 1;
`;

export const ColumnRight = styled.div`
  flex: 1;
  padding-top: 1rem;

  transition: opacity 0.1s ease-in;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;

export const GroupListTableRow = styled.tr`
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#e1e1e1' : 'initial')};
`;
