import { Nav } from 'reactstrap';

import styled from 'styled-components';

export const NavTabs = styled(Nav)``;

export const CampaignHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CampaignTitle = styled.h3`
  margin-left: 12px;
  margin-bottom: 0;
`;
