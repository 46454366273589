import styled from 'styled-components';

export const Container = styled.div`
  transition: opacity 0.1s ease-out;
  opacity: 1;

  @media screen and (max-width: 768px) {
    position: absolute;
    background: white;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const GroupName = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

export const GroupDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

export const GroupPermissionToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 5px;
`;

export const ToolbarLeftSide = styled.div`
  min-width: 0;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ToolbarRightSide = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > button {
    margin-left: 8px;
  }
`;
