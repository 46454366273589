import { createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { PermissionTree, Role } from '../../utils';

type SelectedPermissions = { [key: string]: boolean };

interface Props {
  selected: SelectedPermissions;
  permissions: PermissionTree;
  roleList: Role[];
  setSelected: (perms: SelectedPermissions) => void;
  setRoleList: (role: Role[]) => void;
  setPermission: (key: string, value: boolean) => void;
}

export const GroupPermissionContext = createContext<Props>({
  selected: {},
  permissions: [],
  roleList: [],
  setSelected() {
    throw new Error('Undefined method');
  },
  setRoleList() {
    throw new Error('Undefined method');
  },
  setPermission() {
    throw new Error('Undefined method');
  },
});

export function useSelectedRole(): Role | undefined {
  const params = useParams<{ id?: string }>();
  const { roleList, setSelected } = useContext(GroupPermissionContext);
  const routeParamId = params.id ? parseInt(params.id, 10) : 0;

  const role = useMemo(() => {
    if (routeParamId) {
      return roleList?.find((item) => item.role_id === routeParamId);
    } else {
      return undefined;
    }
  }, [routeParamId, roleList]);

  useEffect(() => {
    const items: { [key: string]: boolean } = {};

    role?.permissions?.forEach((perm) => {
      items[`perm_${perm.perm_id}`] = true;
    });

    setSelected(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return role;
}
