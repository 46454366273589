// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap';

import Person from '../../icons/Person';
import { getUser, logout } from '../../services/auth';
import { UnidadeContext } from '../../services/context';
import { MenuBrandVertiba, MenuNavbar, MenuNavbarToogler } from './styles';

interface Props {
  toggleMenu: () => void;
}

const Menu: React.FC<Props> = (props) => {
  const { unidade } = useContext(UnidadeContext);
  const history = useHistory();
  const user = getUser();

  function doLogout() {
    // Faz o logout do usuário
    logout();

    // Faz reload na página para recarregar as permissões
    history.go(0);
  }

  return (
    <MenuNavbar light className="Menu__Container shadow-sm" fixed="top" expand="md">
      <MenuNavbarToogler id="menu-collapse" onClick={props.toggleMenu} />
      {/* <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        // onClick={(e) => {
        //   history.goBack();
        //   e.preventDefault();
        //   e.stopPropagation();
        // }}
      >
        {history.length > 2 && <FontAwesomeIcon icon={faChevronLeft} style={{ width: 18, height: 18 }} />}
      </div> */}
      <MenuBrandVertiba alt="Logo Vertiba" src={require('../../assets/logo-vertiba.png')} />
      <h6 className="m-auto d-none d-md-block">{unidade?.name}</h6>
      <Nav navbar className="ml-0 ml-xs-auto">
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle className="d-flex align-items-center" nav caret>
            <Person />
            <span className="d-none d-md-block px-2">{user.name}</span>
          </DropdownToggle>
          <DropdownMenu right className="position-absolute">
            <DropdownItem header className="d-block d-md-none">
              {user.name}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={doLogout}>Sair</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </MenuNavbar>
  );
};

export default Menu;
