import styled from 'styled-components';

export const Container = styled.div``;
export const TitleDetail = styled.div``;

export const CampanhaStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CampanhaStatusSpinner = styled.div`
  --spinner-height: 24px;
  --spinner-width: 54px;
  --spinner-time: 300ms;

  display: flex;
  align-items: center;
  background-color: var(--gray);
  border: 2px solid var(--gray);
  height: var(--spinner-height);
  width: var(--spinner-width);
  border-radius: var(--spinner-height);
  transition: all var(--spinner-time) ease-out;
  box-sizing: content-box;
  cursor: pointer;

  &.active {
    background-color: var(--success);
    border-color: var(--success);
  }

  &.active > div {
    transform: translateX(calc(var(--spinner-width) - var(--spinner-height)));
  }
`;

export const CampanhaStatusSpinnerIndicator = styled.div`
  transition: all var(--spinner-time) ease-out;
  background-color: white;
  width: var(--spinner-height);
  height: var(--spinner-height);
  border-radius: var(--spinner-height);
`;

export const CampanhaStatusText = styled.div`
  margin-left: 12px;
  font-size: 16px;
`;
