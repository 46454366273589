import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, Input, InputProps, Label } from 'reactstrap';

import { useField } from '@unform/core';

import { Role } from '../../../utils';

interface Props extends InputProps {
  name: string;
  label?: string;
  roles: Role[];
}

const FieldInput: React.FC<Props> = (props) => {
  const { name, label, roles } = props;
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormGroup>
      {label && <Label for={fieldName}>{label}</Label>}

      <Input type="select" id={fieldName} value={value} innerRef={inputRef} onChange={(e) => setValue(e.target.value)}>
        {roles.map((role) => (
          <option key={role.role_id} value={role.role_id}>
            {role.role_name}
          </option>
        ))}
      </Input>
      {error && <span>{error}</span>}
    </FormGroup>
  );
};

export default FieldInput;
