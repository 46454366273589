import React from 'react';

import Page from '../../components/Page';
import IframeUser from './IframeUser';

const PainelOperador: React.FC = () => {
  return (
    <Page full className="d-flex align-items-center justify-content-center">
      <IframeUser />
    </Page>
  );
};

export default PainelOperador;
