import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import querystring from 'querystring';

import Page from '../../components/Page';
import { getUser } from '../../services/auth';
import { UnidadeContext } from '../../services/context';

type Props = RouteComponentProps<{
  parent?: string;
  page: string;
}>;

const Rajada: React.FC<Props> = (props) => {
  const { unidadeUrl } = useContext(UnidadeContext);
  const { fop2_user = '', fop2_pass = '' } = getUser();
  const [iframeHeight, setIframeHeight] = useState('100%');
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const route_page = props.match.params.page;

  const url = useMemo(() => {
    const params = querystring.stringify({
      exten: fop2_user,
      pass: fop2_pass,
      menu: route_page,
      _: timestamp,
    });

    return `${unidadeUrl}/index.php?${params}`;
  }, [unidadeUrl, fop2_pass, fop2_user, route_page, timestamp]);

  function onMessage(e: MessageEvent) {
    const { action, height } = e.data;

    switch (action) {
      case 'resize':
        height !== iframeHeight && setIframeHeight(height);
        break;
      case 'redirect':
        setTimestamp(new Date().getTime());
        break;
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Rajada de chamadas">
      <iframe
        src={url}
        name="callcenter"
        title="CallCenter frame"
        className="w-100"
        style={{ minHeight: '90%' }}
        height={iframeHeight}
        frameBorder={0}
      />
    </Page>
  );
};

export default Rajada;
