import React, { useRef, useState } from 'react';

import { faBackspace, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Page from '../../components/Page';
import PhoneInput, { PhoneInputElement } from './PhoneInput';
import {
  Container,
  PhoneButtonContainer,
  PhoneButtonContent,
  PhoneButtonDigit,
  PhoneButtonLabel,
  PhoneButtonsContainer,
} from './styles';

interface PhoneButtonProps {
  digit?: number | string;
  label?: string;
  color?: string;
  onClick: (digit: number | string) => void;
  dtmf?: string[];
}

const PhoneButton: React.FC<PhoneButtonProps> = (props) => {
  const { digit, label, color, children, dtmf, onClick } = props;
  const audio = new Audio(dtmf?.[0]);
  audio.preload = 'auto';

  function press() {
    if (audio) {
      if (!audio.paused) {
        audio.currentTime = 0;
      }

      audio.play();
    }

    onClick(digit as string);
  }

  return (
    <PhoneButtonContainer>
      <PhoneButtonContent style={{ backgroundColor: color }} onClick={press}>
        {children || <PhoneButtonDigit>{digit}</PhoneButtonDigit>}
        {label && <PhoneButtonLabel>{label}</PhoneButtonLabel>}
      </PhoneButtonContent>
    </PhoneButtonContainer>
  );
};

const Phone: React.FC = () => {
  const [calling, setCalling] = useState(false);
  const phoneInput = useRef<PhoneInputElement>(null);

  function addChar(digit: string | number) {
    phoneInput.current?.add(digit);
  }

  function backspace() {
    phoneInput.current?.backspace();
  }

  return (
    <Page full style={{ overflow: 'hidden' }}>
      <Container>
        <PhoneInput ref={phoneInput} />
        <PhoneButtonsContainer>
          <PhoneButton
            digit={1}
            label=""
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_1.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_1.ogg',
            ]}
          />
          <PhoneButton
            digit={2}
            label="abc"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_2.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_2.ogg',
            ]}
          />
          <PhoneButton
            digit={3}
            label="def"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_3.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_3.ogg',
            ]}
          />
          <PhoneButton
            digit={4}
            label="ghi"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_4.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_4.ogg',
            ]}
          />
          <PhoneButton
            digit={5}
            label="jkl"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_5.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_5.ogg',
            ]}
          />
          <PhoneButton
            digit={6}
            label="mno"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_6.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_6.ogg',
            ]}
          />
          <PhoneButton
            digit={7}
            label="pqrs"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_7.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_7.ogg',
            ]}
          />
          <PhoneButton
            digit={8}
            label="tuv"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_8.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_8.ogg',
            ]}
          />
          <PhoneButton
            digit={9}
            label="wxyz"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_9.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_9.ogg',
            ]}
          />
          <PhoneButton
            digit="&lowast;"
            label=""
            onClick={() => addChar('*')}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_star.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_star.ogg',
            ]}
          />
          <PhoneButton
            digit={0}
            label="+"
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_0.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_0.ogg',
            ]}
          />
          <PhoneButton
            digit="#"
            label=""
            onClick={addChar}
            dtmf={[
              'https://painel.vertiba.com.br/themes/audio/DTMF_pound.mp3',
              'https://painel.vertiba.com.br/themes/audio/DTMF_pound.ogg',
            ]}
          />
          <PhoneButton color="transparent" onClick={() => {}} />
          <PhoneButton
            color={calling ? 'var(--red)' : '#3a7228'}
            onClick={() => setCalling((prev) => !prev)}
            // label="50:01"
          >
            <FontAwesomeIcon
              style={{
                width: 24,
                height: 24,
                transition: '.3s ease-out',
                transform: calling ? 'rotate(-135deg)' : 'none',
              }}
              icon={faPhone}
            />
          </PhoneButton>
          <PhoneButton color="transparent" onClick={backspace}>
            <FontAwesomeIcon style={{ width: 24, height: 24 }} color="#333" icon={faBackspace} />
          </PhoneButton>
        </PhoneButtonsContainer>
      </Container>
    </Page>
  );
};

export default Phone;
