import React, { useRef, useState } from 'react';
import { ContainerProps } from 'reactstrap';

import BottomTab from '../BottomTab';
import Menu from '../Menu';
import Sidebar from '../Sidebar';
import { ContainerRow, Content, FooterContainer } from './styles';

interface Props extends ContainerProps {
  menu?: boolean;
  full?: boolean;
  centered?: boolean;
  title?: string;
}

export const PageTitle: React.FC = ({ children }) => {
  return <h3 className="mb-4">{children}</h3>;
};

const Page: React.FC<Props> = (props) => {
  const { menu = true, full = false, children, title, ...rest } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {menu && <Menu toggleMenu={() => setSidebarOpen((open) => !open)} />}
      <ContainerRow hasMenu={menu} full={full}>
        {menu && <Sidebar isOpen={sidebarOpen} onDismiss={() => setSidebarOpen(false)} />}
        <Content ref={contentRef} role="main" hasMenu={menu} full={full} className="col" {...rest}>
          {title && <PageTitle>{title}</PageTitle>}
          {children}
        </Content>
        {!full && (
          <FooterContainer className={menu && 'Footer__Container sidebar-offset ml-auto'}>
            <div className="d-flex justify-content-center">
              <small className="text-muted ">Copyright © 2020 Vertiba. Todos os direitos reservados</small>
            </div>
          </FooterContainer>
        )}
      </ContainerRow>
      {menu && <BottomTab />}
    </>
  );
};

export default Page;
