import { NavLink as RNavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'reactstrap';

import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(6px);
  user-select: none;
  -webkit-touch-callout: none;

  /* top: */

  @media screen and (max-width: 768px) {
    height: var(--bottombar-height);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: thin solid rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
    margin: 0;
  }
`;

export const BottomTabItem = styled(NavLink).attrs({ tag: RNavLink })`
  display: flex;
  flex: 1 1 0px;
  width: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  color: #6b71a2;
  transition: color 0.1s;

  &:focus,
  &:hover {
    color: #6b71a2;
  }

  &.active {
    color: #3a7228;
  }
`;

export const SVGIcon = styled(ReactSVG)`
  min-height: 20px;
  margin-bottom: 2px;

  svg {
    height: 20px;
  }
`;
