import React from 'react';

import PhoneTableCol from '../../../components/PhoneTableCol';
import WhatappTableButton from '../../../components/WhatappTableButton';
import { formatDateTime, formatSeconds } from '../../../utils';
import { TableRow } from './styles';

interface Props extends LostCall {
  index: number;
}

const LostCallsItem: React.FC<Props> = (props) => {
  const { index, duration } = props;
  const durationSecs = (duration as unknown) as number;
  return (
    <TableRow>
      <td>{props.name || '-'}</td>
      <td>
        <PhoneTableCol id={'src_' + index} phone={props.src} />
      </td>
      <td>
        <PhoneTableCol id={'dst_' + index} phone={props.dst} />
      </td>
      <td>{formatSeconds(durationSecs)}</td>
      <td>{formatDateTime(props.date)}</td>
      <td className="text-center">
        <WhatappTableButton src={props.src} dst={props.dst} />
      </td>
    </TableRow>
  );
};

export default LostCallsItem;
