/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const TOKEN_KEY = '@vertiba-painel-Token';
export const USER_KEY = '@vertiba-painel-User';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export class AuthUser {
  private static instance: AuthUser;
  private user: User;
  private token: string | null;

  private constructor() {
    this.user = this.getUser();
    this.token = this.getToken();
  }

  /**
   * Generate a user instance if not exists
   */
  public static getInstance(): AuthUser {
    if (!AuthUser.instance) {
      AuthUser.instance = new AuthUser();
    }

    return AuthUser.instance;
  }

  public login(data: User & { token: string }) {
    const { token, ...user } = data;

    // Coloca os dados nas propriedades
    this.user = user;
    this.token = token;

    // Salva no localstorage
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public logout() {
    localStorage.removeItem(TOKEN_KEY);
  }

  public getToken() {
    return this.token || localStorage.getItem(TOKEN_KEY);
  }

  public getUser(): User {
    return JSON.parse(localStorage.getItem(USER_KEY) || '{}');
  }

  get id() {
    return this.user.id;
  }

  public isAuthenticated() {
    return this.token !== null;
  }

  public hasPermission(key: string) {
    return !!this.user.permissions?.[key];
  }
}

export function getUser(): User {
  return JSON.parse(localStorage.getItem(USER_KEY) || '{}');
}

export const login = (data: User & { token: string }) => {
  const { token, ...user } = data;

  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
