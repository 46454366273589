import '@kenshooui/react-multi-select/dist/style.css';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import MultiSelect, { MultiSelectItem } from '@kenshooui/react-multi-select';
import moment from 'moment';

import SectionLoading from '../../../components/SectionLoading';
import { serverApi } from '../../../services/api';
import { UnidadeContext } from '../../../services/context';
import { ToolbarRightButtonContainer } from './styles';

/**
 * items url: /painel/cdr/cdr/list-users
 * result url: /painel/cdr/cdr-ligacoes/list?cdrLigacoes_data_inicial=29/07/2020&cdrLigacoes_data_final=29/07/2020&cdrLigacoes_selecteds=1201,1202,1203,1204,201,202,203,204,210,211,212,213,214,215,216,217,218,219,220,221,222,223,231,301,302,310,311,312,331,501,601,602,603,604,605,607,608,701,900,901,916,917,918,919
 */

interface AsteriskUser {
  extension: string;
  name: string;
}

type AsteriskUsers = AsteriskUser[];

export type RelatorioEfetuadasFields = {
  selected: MultiSelectItem[];
  startDate: string;
  endDate: string;
};

interface Props {
  onSubmit: (o: RelatorioEfetuadasFields) => void;
}

const FormRelatorioEfetuadas: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const { loading: clientLoading } = useContext(UnidadeContext);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<MultiSelectItem[]>([]);
  const [users, setUsers] = useState<AsteriskUsers>([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const pageLoading = clientLoading || loading;

  useEffect(() => {
    if (!clientLoading) {
      setLoading(true);
      serverApi
        .get('/vertiba-api/asterisk-users.list.php')
        .then(({ data }) => setUsers(data.data))
        .finally(() => setLoading(false));
    }
  }, [clientLoading]);

  const items = useMemo(() => {
    return users.map((user) => ({
      id: user.extension,
      label: user.extension + ' - ' + user.name,
    }));
  }, [users]);

  function submitReport() {
    onSubmit({
      selected,
      startDate,
      endDate,
    });
  }

  return (
    <>
      <Row form>
        <Col>
          <FormGroup>
            <Label>Data inicial</Label>
            <Input bsSize="sm" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Data final</Label>
            <Input bsSize="sm" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <ToolbarRightButtonContainer>
            <Button size="sm" onClick={submitReport}>
              Mostrar relatório
            </Button>
          </ToolbarRightButtonContainer>
        </Col>
      </Row>
      <SectionLoading isLoading={pageLoading}>
        <MultiSelect
          items={items}
          selectedItems={selected}
          onChange={setSelected}
          messages={{
            searchPlaceholder: 'Buscar...',
            noItemsMessage: 'Sem ramais...',
            noneSelectedMessage: 'Nenhum ramal selecionado',
            selectedMessage: 'selecionado',
            selectAllMessage: 'Selecionar Todos',
            clearAllMessage: 'Limpar Tudo',
          }}
        />
      </SectionLoading>
    </>
  );
};

export default FormRelatorioEfetuadas;
