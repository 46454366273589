import React from 'react';
import { Nav } from 'reactstrap';

import {
  faArchive,
  faColumns,
  faLevelDownAlt,
  faLevelUpAlt,
  faPhone,
  faPhoneSlash,
  faRecordVinyl,
  faUserFriends,
  faUsers,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

import { AuthUser } from '../../services/auth';
import RouteNavItem from '../RouteNavItem';
import { useSmoothDisplay } from '../SectionLoading/utils';
import NavSectionCallCenter from './NavSectionCallCenter';
import { Container, SidebarBackdrop, SidebarCollapse, SidebarGlobal } from './styles';

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

const Sidebar: React.FC<Props> = (props) => {
  const { isOpen, onDismiss } = props;
  const backdropRef = useSmoothDisplay(isOpen, 350);
  const user = AuthUser.getInstance();

  return (
    <Container className="Sidebar__Container">
      <SidebarBackdrop ref={backdropRef} isOpen={isOpen} onClick={onDismiss} />
      <SidebarCollapse className="bg-light sidebar" isOpen={isOpen}>
        <SidebarGlobal />
        <div className="sidebar-sticky pt-3">
          <Nav className="flex-column">
            <RouteNavItem
              to="/operator-panel"
              label="Painel do operador"
              hasPermission={user.hasPermission('operator-panel')}
              icon={faColumns}
            />
            <RouteNavItem
              to="/fop2-recs"
              label="Gravações FOP2"
              hasPermission={user.hasPermission('fop2-recs')}
              icon={faRecordVinyl}
            />
            {/* <RouteNavItem to="/whatsapp-campaign" label="Disparo whatsapp" iconSrc="/images/svg/disparo-whatsapp.svg" /> */}
            <NavSectionCallCenter />
            <RouteNavItem to="/report" label="Relatórios" hasPermission={user.hasPermission('report')} icon={faArchive}>
              <RouteNavItem
                to="/report/all-calls"
                label="Todas as ligações"
                hasPermission={user.hasPermission('report-all-calls')}
                icon={faPhone}
              />
              <RouteNavItem
                to="/report/lost-calls"
                label="Ligações perdidas"
                hasPermission={user.hasPermission('report-lost-calls')}
                icon={faPhoneSlash}
              />
              {(user.hasPermission('report-outgoing') || user.hasPermission('report-incoming')) && (
                <li className="w-100">
                  <hr style={{ margin: '8px 16px' }} />
                </li>
              )}
              <RouteNavItem
                to="/report/outgoing"
                label="Chamadas efetuadas"
                hasPermission={user.hasPermission('report-outgoing')}
                icon={faLevelUpAlt}
              />
              <RouteNavItem
                to="/report/incoming"
                label="Chamadas recebidas"
                hasPermission={user.hasPermission('report-incoming')}
                icon={faLevelDownAlt}
              />
            </RouteNavItem>
            <RouteNavItem
              to="/users"
              label="Usuários do sistema"
              icon={faUserFriends}
              hasPermission={user.hasPermission('users')}
            >
              <RouteNavItem
                to="/users/list"
                label="Usuários"
                icon={faUsers}
                hasPermission={user.hasPermission('users-list')}
              />
              <RouteNavItem
                to="/users/groups"
                label="Grupos e permissões"
                icon={faUsersCog}
                hasPermission={user.hasPermission('users-groups')}
              />
            </RouteNavItem>
          </Nav>
        </div>
      </SidebarCollapse>
    </Container>
  );
};

export default Sidebar;
