import React from 'react';

import { faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons';

import { ListPagesItem } from '../../components/ListPages';
import { ListPagesContainer } from '../../components/ListPages/styles';
import Page from '../../components/Page';

const Usuarios: React.FC = () => {
  return (
    <Page>
      <h3>Usuários</h3>
      <ListPagesContainer>
        <ListPagesItem to="/users/list" label="Usuários" icon={faUsers} permission="users-list" />
        <ListPagesItem to="/users/groups" label="Grupos e permissões" icon={faUsersCog} permission="users-groups" />
      </ListPagesContainer>
    </Page>
  );
};

export default Usuarios;
