import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, CustomInput, Input, Row, Table } from 'reactstrap';

import Checkbox from '../../../components/Checkbox';
import { PageTitle } from '../../../components/Page';
import vertibaApi from '../../../services/api';
import { PermissionList } from '../../../utils';
import UserGroupForm from '../UserGroupForm';
import UserGroupPermission from '../UserGroupPermission';
import { GroupPermissionContext, useSelectedRole } from '../utils';
import { ColumnLayout, ColumnLeft, ColumnRight, Container, GroupListTableRow } from './styles';

interface Role {
  role_id: number;
  role_name: string;
  role_description: string;
  permissions: PermissionList;
}

const UserGroupList: React.FC = () => {
  // const { client, loading: clientLoading } = useContext(UnidadeContext);
  // const [roles, setRoles] = useState<Role[]>([]);
  const { roleList, setRoleList } = useContext(GroupPermissionContext);
  const [, setLoading] = useState(true);
  const [, setSearch] = useState('');
  const [selected, setSelected] = useState(false);
  const [formGroup, setFormGroup] = useState<Partial<Role>>();
  const role = useSelectedRole();
  const history = useHistory();
  const location = useLocation();

  function loadRoles() {
    setLoading(true);

    vertibaApi
      .get('/roles')
      .then(({ data }) => setRoleList(data.data))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function editRole(role: Role) {
    const path = location.pathname;
    const role_url = `/users/groups/${role.role_id}`;

    if (path !== role_url) {
      history.push(role_url);
    }
  }

  return (
    <Container>
      <PageTitle>Grupos e permissões</PageTitle>
      <ColumnLayout>
        <ColumnLeft active={role !== undefined}>
          <Row>
            <Col className="mb-3 mb-md-0">
              <Button
                className="mr-2"
                color={selected ? 'danger' : undefined}
                disabled={!selected}
                children="Excluir"
                size="sm"
              />
              <Button className="mr-2" color="primary" size="sm" onClick={() => setFormGroup({})}>
                Novo grupo
              </Button>
            </Col>
            <Col md={4}>
              <Input bsSize="sm" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
            </Col>
          </Row>
          <Table className="mt-5" size="sm" borderless responsive hover>
            <thead className="border-bottom">
              <tr>
                <th>
                  <CustomInput
                    type="checkbox"
                    id="client-all"
                    defaultChecked={selected}
                    onChange={(e) => setSelected(e.target.checked)}
                  />
                </th>
                <th>Grupo</th>
                <th className="w-100">Descrição</th>
                <th className="text-center">Permissões</th>
              </tr>
            </thead>
            <tbody>
              {roleList.map((item) => (
                <GroupListTableRow
                  key={`role-${item.role_id}`}
                  onClick={() => editRole(item)}
                  active={role?.role_id === item.role_id}
                >
                  <td>
                    <Checkbox id={`role-${item.role_id}`} />
                  </td>
                  <td>{item.role_name}</td>
                  <td>{item.role_description}</td>
                  <td className="text-center">{item.permissions.length}</td>
                </GroupListTableRow>
              ))}
            </tbody>
          </Table>
        </ColumnLeft>
        <ColumnRight className={location.pathname !== '/users/groups' ? 'active' : ''}>
          <Route path="/users/groups/:id" component={UserGroupPermission} />
        </ColumnRight>
      </ColumnLayout>

      <UserGroupForm
        isOpen={!!formGroup}
        onRefresh={loadRoles}
        initialData={formGroup as Role}
        toggle={() => setFormGroup((user) => (user ? undefined : {}))}
      />
    </Container>
  );
};

export default UserGroupList;
