import styled from 'styled-components';

export const Container = styled.div``;

export const ListPagesContainer = styled.div`
  padding-top: 12px;
`;

export const ListPagesItemContainer = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid #f0f0f0;
  padding: 12px 0;
  cursor: pointer;

  & > :first-child {
    margin-right: 8px;
  }

  :last-child {
    border-bottom: none;
  }
`;

export const ListPagesItemLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  flex: 1;
`;
