import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import PhoneNumber from '../../../classes/PhoneNumber';
import { PhoneInputContainer, PhoneInputField } from './styles';

export interface PhoneInputElement {
  add: (digit: string | number) => void;
  backspace: () => void;
  clear: () => void;
}

const PhoneInput: React.RefForwardingComponent<PhoneInputElement> = (props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const state = useRef<{ value: string }>({ value: '' });

  useImperativeHandle(
    ref,
    () => ({
      add: (digit) => {
        if (inputRef.current) {
          const prev = state.current.value;
          state.current.value = prev + digit;

          inputRef.current.value = new PhoneNumber(state.current.value).format();
        }
      },
      backspace: () => {
        if (inputRef.current) {
          const prev = state.current.value;
          state.current.value = prev.slice(0, -1) || '';

          inputRef.current.value = new PhoneNumber(state.current.value).format();
        }
      },
      clear: () => {
        if (inputRef.current) {
          state.current.value = '';
          inputRef.current.value = '';
        }
      },
      value: () => inputRef.current?.value,
    }),
    [],
  );

  return (
    <PhoneInputContainer>
      <PhoneInputField ref={inputRef} /* onChange={(e: any) => new PhoneNumber(e.target.value).format()} */ />
      {/* <input onChange /> */}
    </PhoneInputContainer>
  );
};

export default forwardRef(PhoneInput);
