import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import { Form } from '@unform/web';

import FieldInput from '../../../components/FieldInput';
import api from '../../../services/api';
import { Role } from '../../../utils';

interface Props {
  isOpen: boolean;
  initialData: Role;
  onRefresh: () => void;
  toggle: () => void;
}

const UserGroupForm: React.FC<Props> = (props) => {
  const { isOpen, initialData, onRefresh, toggle } = props;
  const [loading, setLoading] = useState(false);
  const action = initialData?.role_id ? 'update' : 'create';

  async function onSubmit(values: any) {
    setLoading(true);

    try {
      // Coloca o id do usuário no path caso seja update
      const path = action === 'update' ? '/' + initialData?.role_id : '';

      // Faz o request de POST para creae e PUT para update
      await api.request({
        url: `/roles${path}`,
        method: action === 'create' ? 'POST' : 'PUT',
        data: values,
      });

      toggle();
      onRefresh();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <Form onSubmit={onSubmit} initialData={initialData}>
        <ModalHeader toggle={toggle}>{action === 'create' ? 'Novo' : 'Editar'} grupo</ModalHeader>
        <ModalBody>
          <FieldInput name="role_name" type="text" label="Nome" placeholder="Nome do grupo" />
          <FieldInput name="role_description" type="text" label="Descrição" placeholder="Descrição do grupo" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>{' '}
          <Button color="success" type="submit" disabled={loading}>
            {loading && <Spinner size="sm" />}
            {loading ? ' Salvando...' : 'Salvar'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserGroupForm;
