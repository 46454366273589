import React from 'react';
import { Col, Row } from 'reactstrap';

import PaginationButtons from '../PaginationButtons';

interface Props {
  total: number;
  page: number;
  pageLength: number;
  onChangePage: (page: number) => void;
}

const SmartPagination: React.FC<Props> = (props) => {
  const { page, pageLength, total, onChangePage } = props;
  const offset = (page - 1) * 25 + 1;
  const lastItem = offset + pageLength - 1;

  return (
    <Row>
      <Col className="d-flex d-md-block justify-content-center">
        <p>{total ? `Exibindo de ${offset} a ${lastItem} de ${total} registros` : 'Nenhum registro encontrado'}</p>
      </Col>
      <Col md="auto">
        <PaginationButtons defaultPage={page} onChangePage={onChangePage} totalRows={total} />
      </Col>
    </Row>
  );
};

export default SmartPagination;
