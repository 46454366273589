import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getToken } from './auth';

export type ServerApiResponseProps<T> = {
  success: boolean;
  message: string;
  data: T;
};

export type VertibaApiResponseProps<T> = {
  success: boolean;
  message: string;
  data: T;
};

export type ServerApiResponse<T> = AxiosResponse<ServerApiResponseProps<T>>;
export type VertibaApiResponse<T> = AxiosResponse<VertibaApiResponseProps<T>>;

export type ServerPromiseResponse<T> = Promise<ServerApiResponse<T>>;
export type VertibaPromiseResponse<T> = Promise<ServerApiResponse<T>>;

// Api do servidor asterisk
export const serverApi = axios.create({});

// Api da vertiba
export const vertibaApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authInterceptor = async (config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

vertibaApi.interceptors.request.use(authInterceptor);
serverApi.interceptors.request.use(authInterceptor);

export default vertibaApi;
