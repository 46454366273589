import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import moment from 'moment';

import CdrAudio from '../../classes/CdrAudio';
import CallsFormFilter from '../../components/CallsFormFilter';
import DownloadAudioModal from '../../components/DownloadAudioModal';
import Page from '../../components/Page';
import SectionLoading from '../../components/SectionLoading';
import SmartPagination from '../../components/SmartPagination';
import { getUser } from '../../services/auth';
import { UnidadeContext } from '../../services/context';
import { fetchCalls, fix_call_audio_path } from '../../utils';
import CallsItem from './CallsItem';

interface CallsResult {
  data: Calls;
  count: number;
}

function fix_audio_url(call: Call) {
  if (call.audio && !~call.audio.search('/')) {
    const date = call.date.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$1/$2/$3');
    return `/var/spool/asterisk/monitor/${date}/${call.audio}`;
  }

  return call.audio;
}

const CallsList: React.FC = () => {
  const { unidadeUrl, loading: clientLoading } = useContext(UnidadeContext);
  const user = getUser();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<CallsResult>({ count: 0, data: [] });
  const [callListen, setCallListen] = useState<Call>();
  const [filter, setFilter] = useState<CallsPagingFilter>({
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    filter: 'src',
    search: '',
    page: 1,
  });

  const offset = (filter.page - 1) * 25 + 1;
  const pageLoading = clientLoading || loading;

  const loadCalls = useCallback(
    (filter: CallsPagingFilter) => {
      setLoading(true);
      setResult((result) => ({ ...result, data: [] }));

      fetchCalls(filter, user)
        .then(({ data }) => setResult(data))
        .finally(() => setLoading(false));
    },
    [user],
  );

  function onChangeFilter(filter: CallsFilter) {
    setFilter({ ...filter, page: 1 });
  }

  useEffect(() => {
    clientLoading || loadCalls(filter);
    // eslint-disable-next-line
  }, [clientLoading, filter]);

  return (
    <Page className="bg-white" title="Todas as ligações">
      <CallsFormFilter filter={filter} onChangeFilter={onChangeFilter} />
      <SectionLoading isLoading={pageLoading}>
        <Table className="mt-3" size="sm" borderless responsive hover>
          <thead className="border-bottom">
            <tr>
              <th>Chamador</th>
              <th>Destino</th>
              <th className="text-center">Tipo</th>
              <th className="text-center">Status</th>
              <th className="text-center">Duração</th>
              <th>Data</th>
              <th className="text-center">WhatsApp</th>
              <th className="text-center">Gravação</th>
            </tr>
          </thead>
          <tbody>
            {result.data?.map((call, index) => (
              <CallsItem
                {...call}
                key={`${call.id}.${call.dst}`}
                index={offset + index}
                audio={fix_audio_url(call)}
                onPlayAudio={() => {
                  setCallListen({ ...call, audio: fix_audio_url(call) });
                }}
              />
            ))}
          </tbody>
        </Table>
        <SmartPagination
          page={filter.page}
          total={result.count}
          pageLength={result.data.length}
          onChangePage={(page) => setFilter((prev) => ({ ...prev, page }))}
        />
      </SectionLoading>
      <DownloadAudioModal
        isOpen={!!callListen}
        cdrAudio={new CdrAudio(unidadeUrl, fix_call_audio_path(callListen))}
        onClose={() => setCallListen(undefined)}
      />
    </Page>
  );
};

export default CallsList;
