import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root{
    --bottombar-height: 50px;
    --sidebar-offset: 210px;
    --content-width: calc(100% - var(--sidebar-offset));

    /* App colors */
    --green: #00a859;
    --blue: #6b71a2;

    --color-primary: #6b71a2;
    --primary: var(--blue);
    --success: var(--green);
  }
`;
