import React, { useState } from 'react';
import { matchPath } from 'react-router';
import { NavLink as RNavLink, NavLinkProps as RNavLinkProps, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Collapse, Nav, NavLink, NavLinkProps } from 'reactstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface RouteNavCollapseProps extends RNavLinkProps, Omit<NavLinkProps, 'onClick'> {
  icon?: IconProp;
  iconSrc?: string;
  label?: string;
}

const RouteNavCollapse: React.FC<RouteNavCollapseProps> = (props) => {
  const { ref, label, icon, iconSrc, children, ...rest } = props;
  const { pathname } = useLocation();
  const match = matchPath(pathname, rest.to as string);
  const [isOpen, setOpen] = useState(!!match);

  return (
    <>
      <NavLink
        className="d-flex align-items-center"
        activeClassName="active"
        tag={RNavLink}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen((open) => !open);
        }}
        {...rest}
      >
        {(icon || iconSrc) && (
          <div className="mr-2">
            {iconSrc && <ReactSVG style={{ width: 16 }} src={iconSrc} />}
            {icon && <FontAwesomeIcon style={{ width: 16 }} icon={icon} />}
          </div>
        )}
        <span>{label}</span>
        <FontAwesomeIcon className="ml-auto" style={{ width: 16 }} icon={isOpen ? faCaretUp : faCaretDown} />
      </NavLink>
      <Collapse isOpen={isOpen}>
        <Nav
          className="py-2 border-top border-bottom border-right"
          style={{ background: '#f4f4f4', borderLeft: '1rem solid #dee2e6' }}
        >
          {children}
        </Nav>
      </Collapse>
    </>
  );
};

export default RouteNavCollapse;
