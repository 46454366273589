import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import Page from '../../components/Page';
import vertibaApi from '../../services/api';
import { permissionList2tree, PermissionTree, Role } from '../../utils';
import UserGroupList from './UserGroupList';
import { GroupPermissionContext } from './utils';

const UserGroups: React.FC = () => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [roleList, setRoleList] = useState<Role[]>([]);
  const [permissions, setPermissions] = useState<PermissionTree>([]);

  useEffect(() => {
    vertibaApi.get('/permissions').then(({ data }) => {
      const perms = permissionList2tree(data.data);
      setPermissions(perms);
    });
  }, []);

  function setPermission(key: string, value: boolean) {
    setSelected((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  return (
    <Page>
      <GroupPermissionContext.Provider
        value={{
          permissions,
          selected,
          roleList,
          setSelected,
          setPermission,
          setRoleList,
        }}
      >
        <Route path="/users/groups/:id?" component={UserGroupList} />
      </GroupPermissionContext.Provider>
    </Page>
  );
};

export default UserGroups;
