/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import CdrAudio, { CdrAudioExtensions } from '../../classes/CdrAudio';
import { SelectFileFormat } from './styles';

interface Props {
  extension?: CdrAudioExtensions;
  cdrAudio: CdrAudio;
  isOpen: boolean;
  onClose: () => void;
}

const DownloadAudioModal: React.FC<Props> = (props) => {
  const { cdrAudio, extension = 'wav', isOpen, onClose } = props;
  const [convert, setConvert] = useState<CdrAudioExtensions>(extension);
  const [name, setName] = useState('');

  const audio = useMemo(() => {
    return cdrAudio.rename(name).extension(convert);
  }, [cdrAudio, name, convert]);

  function closeModal() {
    onClose();
    setName('');
  }

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="sm">
      <ModalBody>
        <FormGroup>
          <Label>Nome do arquivo</Label>
          <InputGroup size="sm">
            <Input value={name} placeholder={audio.name} onChange={(e) => setName(e.target.value)} />
            <InputGroupAddon addonType="append">
              <SelectFileFormat
                value={convert}
                className="form-control form-control-sm"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setConvert(e.target.value as CdrAudioExtensions)}
              >
                <option value="mp3">.mp3</option>
                <option value="wav">.wav</option>
              </SelectFileFormat>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <hr />
        {isOpen && (
          <audio controls controlsList="nodownload" className="w-100" style={{ outline: 0 }}>
            <source src={audio.url} type={audio.mimetype} />
          </audio>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="sm" onClick={closeModal}>
          Cancelar
        </Button>
        <Button tag="a" color="success" size="sm" target="_blank" className="ml-auto" href={audio.url}>
          Baixar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DownloadAudioModal;
