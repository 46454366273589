import React from 'react';
import { ReactSVG } from 'react-svg';

import { faRecordVinyl, faUserFriends, faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons';

import { ListPagesItem } from '../../components/ListPages';
import { ListPagesContainer } from '../../components/ListPages/styles';
import Page from '../../components/Page';

const Menu: React.FC = () => {
  return (
    <Page>
      <h3>Menu</h3>
      <ListPagesContainer>
        <ListPagesItem to="/fop2-recs" label="Gravações FOP2" icon={faRecordVinyl} />
        <ListPagesItem
          to="/whatsapp-campaign"
          label="Disparo whatsapp"
          renderIcon={<ReactSVG style={{ width: 16 }} src="/images/svg/disparo-whatsapp.svg" />}
        />
        <ListPagesItem to="/users" label="Usuários do sistema" icon={faUserFriends} permission="users">
          <ListPagesItem to="/users/list" label="Usuários" icon={faUsers} permission="users-list" />
          <ListPagesItem to="/users/groups" label="Grupos e permissões" icon={faUsersCog} permission="users-groups" />
        </ListPagesItem>
      </ListPagesContainer>
    </Page>
  );
};

export default Menu;
