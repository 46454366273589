import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatPhone } from '../../utils';
import { usePhoneDetails } from './utils';

interface Props {
  id: string;
  phone: string;
}

const PhoneTableCol: React.FC<Props> = (props) => {
  const { id, phone } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const details = usePhoneDetails(phone);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon style={{ width: 16 }} icon={details.icon} id={id} />
      <span className="ml-3">{formatPhone(phone)}</span>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        autohide={false}
        target={id}
        children={details.title}
        toggle={toggle}
      />
    </div>
  );
};

export default PhoneTableCol;
