import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import moment from 'moment';

import CallsFormFilter from '../../components/CallsFormFilter';
import Page from '../../components/Page';
import SectionLoading from '../../components/SectionLoading';
import SmartPagination from '../../components/SmartPagination';
import { UnidadeContext } from '../../services/context';
import { fetchLostCalls } from '../../utils';
import LostCallsItem from './LostCallsItem';

interface LostCallsResult {
  data: LostCalls;
  count: number;
}

const LostCalls: React.FC = () => {
  const { loading: clientLoading } = useContext(UnidadeContext);

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<LostCallsResult>({ count: 0, data: [] });
  const [filter, setFilter] = useState<CallsPagingFilter>({
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    filter: 'src',
    search: '',
    page: 1,
  });

  const pageLoading = clientLoading || loading;

  const loadCalls = useCallback((filter: CallsPagingFilter) => {
    setLoading(true);
    setResult((result) => ({ ...result, data: [] }));

    fetchLostCalls(filter)
      .then(({ data }) => setResult(data))
      .finally(() => setLoading(false));
  }, []);

  function onChangeFilter(filter: CallsFilter) {
    setFilter({ ...filter, page: 1 });
  }

  useEffect(() => {
    clientLoading || loadCalls(filter);
    // eslint-disable-next-line
  }, [clientLoading, filter]);

  return (
    <Page className="bg-white" title="Ligações perdidas">
      <CallsFormFilter filter={filter} onChangeFilter={onChangeFilter} />
      <SectionLoading isLoading={pageLoading}>
        <Table className="mt-3" size="sm" borderless responsive hover>
          <thead className="border-bottom">
            <tr>
              <th>Nome</th>
              <th>Chamador</th>
              <th>Destino</th>
              <th>Duração</th>
              <th>Data</th>
              <th className="text-center">WhatsApp</th>
            </tr>
          </thead>
          <tbody>
            {result.data?.map((call, index) => (
              <LostCallsItem key={call.id} index={(filter.page - 1) * 25 + 1 + index} {...call} />
            ))}
          </tbody>
        </Table>
        <SmartPagination
          page={filter.page}
          total={result.count}
          pageLength={result.data.length}
          onChangePage={(page) => setFilter((prev) => ({ ...prev, page }))}
        />
      </SectionLoading>
    </Page>
  );
};

export default LostCalls;
