import React from 'react';
import { Spinner } from 'reactstrap';

// import { Container } from './styles';

interface Props {
  enabled: boolean;
}

const Loading: React.FC<Props> = ({ enabled }) => {
  return enabled ? (
    <div className="pt-2 pb-3 d-flex justify-content-center">
      <Spinner />
    </div>
  ) : null;
};

export default Loading;
