import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Alert, Col } from 'reactstrap';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import ButtonLoading from '../../components/ButtonLoading';
import FieldInput from '../../components/FieldInput';
import Page from '../../components/Page';
import api from '../../services/api';
import { AuthUser } from '../../services/auth';
import { UnidadeContext } from '../../services/context';
import { Container } from './styles';

const Login: React.FC<RouteChildrenProps> = (props) => {
  const { history } = props;
  const { unidade } = useContext(UnidadeContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const errors = formRef.current?.getErrors();
  const user = AuthUser.getInstance();

  useEffect(() => {
    if (user.isAuthenticated()) {
      history.replace('/operator-panel');
    }
  }, [history, user]);

  function onSubmit(values: any) {
    setLoading(true);
    api
      .post(`/unidade/${unidade?.id}/auth`, values)
      .then(({ data }) => {
        if (data.success) {
          user.login(data.data);
          history.replace('/operator-panel');
        } else {
          formRef.current?.setErrors({ submit: data.message });
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <Page menu={false} centered className="bg-white align-items-center">
      <Col xl={3} lg={4} md={5} sm={6} className="mx-auto">
        <Container>
          <img
            alt="Logo Vertiba"
            src={require('../../assets/logo-vertiba.png')}
            className="mb-4 w-100 align-self-center"
          />
          <h4 className="text-center text-muted mb-5">{unidade?.name}</h4>
          <div className="my-4">
            <Form ref={formRef} onSubmit={onSubmit}>
              {errors?.submit && <Alert color="danger">{errors?.submit}</Alert>}
              <FieldInput name="username" label="Usuário" />
              <FieldInput type="password" name="password" label="Senha" />
              <ButtonLoading block label="Entrar" loadingLabel="Entrando..." loading={loading} />
            </Form>
          </div>
        </Container>
      </Col>
    </Page>
  );
};

export default Login;
