import React from 'react';
import { NavLink as RNavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { NavLink, NavLinkProps } from 'reactstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface RouteNavLinkProps extends NavLinkProps {
  icon?: IconProp;
  iconSrc?: string;
  label?: string;
}

const RouteNavLink: React.FC<RouteNavLinkProps> = (props) => {
  const { ref, label, icon, iconSrc, ...rest } = props;

  return (
    <NavLink className="d-flex align-items-center" activeClassName="active" tag={RNavLink} {...rest}>
      {(icon || iconSrc) && (
        <div className="mr-2">
          {iconSrc && <ReactSVG style={{ width: 16 }} src={iconSrc} />}
          {icon && <FontAwesomeIcon style={{ width: 16 }} icon={icon} />}
        </div>
      )}
      {label}
    </NavLink>
  );
};

export default RouteNavLink;
