import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Spinner, Table } from 'reactstrap';

import Page from '../../components/Page';
import { UnidadeContext } from '../../services/context';
import { fetchFop2Users, fetchUsers, submitFop2Users } from '../../utils';
import FormUsuario from './FormUsuario';
import UsuariosItem from './UsuariosItem';

const UsuariosList: React.FC = () => {
  const { unidade, loading: unidadeLoading } = useContext(UnidadeContext);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [synchronize, setSynchronize] = useState(false);
  const [search, setSearch] = useState('');
  const [formUser, setFormUser] = useState<Partial<User>>();

  function usersFilter(user: User) {
    const regexp = new RegExp(`.*${search}.*`, 'gi');
    return (
      search === '' || regexp.test(user.name.toLowerCase()) || regexp.test(user.username) || regexp.test(user.fop2_user)
    );
  }

  const loadUsers = useCallback(async () => {
    if (unidade) {
      setLoading(true);

      try {
        const { data } = await fetchUsers(unidade);
        setUsers(data.data);
      } catch (e) {
        console.error('Erro ao buscar usuários');
      } finally {
        setLoading(false);
      }
    }
  }, [unidade]);

  const sync_fop2 = useCallback(() => {
    if (unidade) {
      setSynchronize(true);
      fetchFop2Users()
        .then(({ data }) => submitFop2Users(unidade, data.data))
        .then(({ data }) => console.log(data))
        .then(loadUsers)
        .finally(() => setSynchronize(false));
    }
  }, [unidade, loadUsers]);

  useEffect(() => {
    unidadeLoading || loadUsers();
  }, [unidadeLoading, loadUsers]);

  return (
    <Page className="bg-white" title="Usuários">
      <Row>
        <Col className="mb-3 mb-md-0">
          {/* <Button
            className="mr-2"
            color={selected ? 'danger' : undefined}
            disabled={!selected}
            children="Excluir"
            onClick={() => {}}
            size="sm"
          /> */}
          <Button className="mr-2" color="primary" size="sm" onClick={() => setFormUser({})}>
            Novo usuário
          </Button>
          <Button size="sm" color="secondary" onClick={sync_fop2} disabled={synchronize}>
            {synchronize ? (
              <>
                <Spinner size="sm" className="mr-2" />
                Sincronizando
              </>
            ) : (
              'Sincronizar com FOP2'
            )}
          </Button>
        </Col>
        <Col md={4}>
          <Input bsSize="sm" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
        </Col>
      </Row>

      <Table className="mt-5" size="sm" borderless responsive hover>
        <thead className="border-bottom">
          <tr>
            {/* <th>
              <CustomInput
                type="checkbox"
                id="unidade-all"
                defaultChecked={selected}
                onChange={(e) => setSelected(e.target.checked)}
              />
            </th> */}
            <th>#</th>
            <th>Nome</th>
            <th>Usuário</th>
            <th>Gerente</th>
            <th>Usuário FOP2</th>
            <th>Data criação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.filter(usersFilter).map((user) => (
            <UsuariosItem key={user.id} onRefresh={loadUsers} onClickEdit={() => setFormUser(user)} {...user} />
          ))}
        </tbody>
      </Table>
      {loading && (
        <div className="pt-2 pb-3 d-flex justify-content-center">
          <Spinner />
        </div>
      )}

      <FormUsuario
        isOpen={!!formUser}
        onRefresh={loadUsers}
        initialData={formUser as User}
        toggle={() => setFormUser((user) => (user ? undefined : {}))}
      />
    </Page>
  );
};

export default UsuariosList;
