import { serverApi, ServerApiResponse } from '../../services/api';

export type CampaignItem = {
  id: string;
  name: string;
  active: boolean;
};

type ResponseCampaignItem = ServerApiResponse<CampaignItem>;

export function fetchWhatsappCampaign(id: string): Promise<ResponseCampaignItem> {
  return serverApi.get('/vertiba-api/get-campaign.php?id=' + id);
}
