import { Navbar, NavbarToggler } from 'reactstrap';

import styled from 'styled-components';

export const MenuNavbar = styled(Navbar)`
  background-color: white;
  transition: background 0.5s ease-out;

  @media screen and (max-width: 768px) {
    background-color: black;
    color: rgba(255, 255, 255, 0.5) !important;
    /* display: flex !important;
    justify-content: space-between;

    & > div {
      background-color: pink;
      flex: 1 0;
      flex-shrink: 1;
      margin: auto;
    } */

    .nav-link {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    .navbar-toggler {
      border: none;
      padding: 4px;
    }
  }
`;

export const MenuNavbarToogler = styled(NavbarToggler)`
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
`;

export const MenuBrandVertiba = styled.img`
  height: 32px;
  margin-right: 12px;
`;
