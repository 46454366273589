import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const LoadingIndicatorContainer = styled.div<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 54px;
  background-color: white;
  z-index: 10;
  display: 'block';
  transition: opacity 0.3s ease-out;
`;
