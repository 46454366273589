import React, { useContext } from 'react';

import Page from '../../components/Page';
import { UnidadeContext } from '../../services/context';

/*
body {
  padding: 0!important;
}

#content {
  margin: 25px;
}

body, #content {
  background-color: white!important;
}

#sidebar,#footer,#chart1,#chart2,#chart3,#chart4,#chart5,#chart6,#chart7,#chart8 {
  display: none;
}
*/

const RelatorioRecebidas: React.FC = () => {
  const { unidadeUrl } = useContext(UnidadeContext);
  return (
    <Page full className="p-0">
      <iframe
        src={unidadeUrl + '/queue-stats'}
        title="Relatório recebidas"
        className="w-100 h-100 position-absolute"
        frameBorder={0}
      />
    </Page>
  );
};

export default RelatorioRecebidas;
