import React, { useContext } from 'react';

import PhoneTableCol from '../../../components/PhoneTableCol';
import WhatappTableButton from '../../../components/WhatappTableButton';
import { UnidadeContext } from '../../../services/context';
import { formatDateTime, formatSeconds } from '../../../utils';

interface Props extends Fop2Recording {
  index: number;
  onPlayAudio: (url: string) => void;
}

const CallsItem: React.FC<Props> = (props) => {
  const { onPlayAudio, duration, index } = props;
  const { unidadeUrl } = useContext(UnidadeContext);

  function playAudio(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    onPlayAudio(`${unidadeUrl}/vertiba/api/recordingfile.php?path=${props.filename}`);
  }

  return (
    <tr>
      <th>{props.index}</th>
      <td>
        <PhoneTableCol id={'src_' + index} phone={props.targetextension} />
      </td>
      <td>
        <PhoneTableCol id={'dst_' + index} phone={props.dst || ''} />
      </td>
      <td>{formatSeconds((duration as unknown) as number)}</td>
      <td>{formatDateTime(props.datetime)}</td>
      <td className="text-center">
        <WhatappTableButton src={props.src as string} dst={props.dst as string} />
      </td>
      <th className="text-center">
        {props.filename ? (
          <a
            href={`${unidadeUrl}/vertiba/api/recordingfile.php?path=${props.filename}`}
            onClick={playAudio}
            title={props.filename}
            target="_blank"
            rel="noopener noreferrer"
            children="ouvir"
          />
        ) : (
          '...'
        )}
      </th>
    </tr>
  );
};

export default CallsItem;
