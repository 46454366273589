import styled from 'styled-components';

export const PhoneInputField = styled.input`
  flex: 1;
  border: none;
  padding: 0;
  height: var(--button-size);
  width: var(--phone-width);
  text-align: center;
  font-size: var(--button-label-width);
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  max-width: var(--phone-width);
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--button-margin-x);
`;
