import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Input, Row, Table } from 'reactstrap';

import useSWR from 'swr';

import { UnidadeContext } from '../../../services/context';
import { fetchWhatsappContacts } from '../../../utils';
import ContatosItem from '../ContatosItem';
import { CampaignItem } from '../utils';

interface Props extends RouteComponentProps {
  campanha?: CampaignItem;
}

const TabContatosAceitos: React.FC<Props> = ({ campanha }) => {
  const { unidade, loading: unidadeLoading } = useContext(UnidadeContext);
  const [search, setSearch] = useState('');

  const { data: contacts, revalidate } = useSWR<Contact[]>(
    `/vertiba/api/campaign/${campanha?.id}/contacts`,
    loadContacts,
  );

  function contactsilter(contact: Contact) {
    const regexp = new RegExp(`.*${search}.*`, 'gi');
    return search === '' || regexp.test(contact.name.toLowerCase()) || regexp.test(contact.phone);
  }

  async function loadContacts() {
    if (unidade && campanha) {
      const { data } = await fetchWhatsappContacts(campanha.id);
      return data.data;
    } else {
      return [];
    }
  }

  useEffect(() => {
    unidadeLoading || revalidate();
  }, [unidadeLoading, revalidate]);

  return (
    <>
      <Row>
        <Col className="mb-3 mb-md-0"></Col>
        <Col md={4}>
          <Input bsSize="sm" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
        </Col>
      </Row>

      <Table className="mt-3" size="sm" borderless responsive hover>
        <thead className="border-bottom">
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th className="text-center">Telefone</th>
            <th className="text-center">WhatsApp</th>
            <th>Data de contato</th>
          </tr>
        </thead>
        <tbody>
          {contacts?.filter(contactsilter).map((contact) => (
            <ContatosItem key={contact.id} {...contact} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TabContatosAceitos;
