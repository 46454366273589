import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import useSWR from 'swr';

import PageLoading from './components/PageLoading';
import PrivateRoute from './components/PrivateRoute';
import SectionLoading from './components/SectionLoading';
import CallRecsFop2 from './pages/CallRecsFop2';
import CallsList from './pages/CallsList';
import CampanhaWhatsapp from './pages/CampanhaWhatsapp';
import CampanhaWhatsappDetail from './pages/CampanhaWhatsappDetail';
import Login from './pages/Login';
import LostCalls from './pages/LostCalls';
import Menu from './pages/Menu';
import NotFound from './pages/NotFound';
import PainelOperador from './pages/PainelOperador';
import Phone from './pages/Phone';
import Rajada from './pages/Rajada';
import RelatorioEfetuadas from './pages/RelatorioEfetuadas';
import RelatorioRecebidas from './pages/RelatorioRecebidas';
import Relatorios from './pages/Relatorios';
import UserGroups from './pages/UserGroups';
import Usuarios from './pages/Usuarios';
import UsuariosList from './pages/UsuariosList';
import api, { serverApi } from './services/api';
import { AuthUser } from './services/auth';
import { UnidadeContext } from './services/context';
import { GlobalStyle } from './styles';
import { getUnidadeUrl } from './utils';

const App: React.FC = () => {
  const domain = process.env.REACT_APP_HOSTNAME || window.location.hostname;

  const [loading, setLoading] = useState(true);
  const [unidadeUrl, setUnidadeUrl] = useState<string>('');
  const { data: unidade, isValidating } = useSWR<Unidade>('/unidade/info', getUnidade, {
    revalidateOnFocus: false,
  });

  async function getUnidade(): Promise<Unidade> {
    const { data } = await api.get('/unidade/info', { timeout: 10000, params: { domain } });
    return data;
  }

  useEffect(() => {
    getUnidadeUrl(domain)
      .then((url: string) => {
        setUnidadeUrl(url);
        serverApi.defaults.baseURL = url;
      })
      .finally(() => setLoading(false));
  }, [domain]);

  useEffect(() => {
    const user = AuthUser.getInstance();

    if (unidade && user.isAuthenticated()) {
      api.get(`/unidade/${unidade?.id}/users/${user.id}`).then(({ data }) => {
        user.login(data.data);
      });
    }
  }, [unidade]);

  return (
    <UnidadeContext.Provider value={{ unidade, loading: loading || isValidating, unidadeUrl }}>
      <GlobalStyle />
      <SectionLoading isLoading={loading} container={Fragment} loadingComponent={PageLoading}>
        <Router basename={process.env.REACT_APP_BASE_URL}>
          <Switch>
            <Redirect exact from="/" to="/operator-panel" />
            <Redirect exact from="/vertiba-painel" to="/operator-panel" />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/operator-panel" component={PainelOperador} />
            <PrivateRoute path="/phone" component={Phone} />
            <PrivateRoute path="/users/list" component={UsuariosList} />
            <PrivateRoute path="/users/groups" component={UserGroups} />
            <PrivateRoute path="/fop2-recs" component={CallRecsFop2} />
            <PrivateRoute path="/whatsapp-campaign" exact component={CampanhaWhatsapp} />
            <PrivateRoute path="/whatsapp-campaign/:id" component={CampanhaWhatsappDetail} />
            <PrivateRoute path="/callcenter/rajada/:parent/:page" component={Rajada} />
            <PrivateRoute path="/callcenter/rajada/:page" component={Rajada} />
            <PrivateRoute path="/report/lost-calls" component={LostCalls} />
            <PrivateRoute path="/report/all-calls" component={CallsList} />
            <PrivateRoute path="/report/outgoing" component={RelatorioEfetuadas} />
            <PrivateRoute path="/report/incoming" component={RelatorioRecebidas} />
            <PrivateRoute path="/report" exact component={Relatorios} />
            <PrivateRoute path="/menu" exact component={Menu} />
            <PrivateRoute path="/users" exact component={Usuarios} />
            <PrivateRoute path="*" component={NotFound} />
          </Switch>
        </Router>
      </SectionLoading>
    </UnidadeContext.Provider>
  );
};

export default App;
