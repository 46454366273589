import React, { useContext, useEffect, useState } from 'react';
import {
  NavLink as RNavLink,
  NavLinkProps,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import ButtonBack from '../../components/ButtonBack';
import Page from '../../components/Page';
import SectionLoading from '../../components/SectionLoading';
import { UnidadeContext, useAfterUnidadeEffect } from '../../services/context';
import { useWebsocket } from '../../utils';
import { NavTabs } from './styles';
import { CampaignHeader, CampaignTitle } from './styles';
import TabCampanhaDetalhes from './TabCampanhaDetalhes';
import TabContatosAceitos from './TabContatosAceitos';
import { CampaignItem, fetchWhatsappCampaign } from './utils';

const TabItem: React.FC<NavLinkProps> = (props) => {
  const history = useHistory();
  return (
    <NavItem>
      <NavLink tag={RNavLink} {...props} onClick={() => history.replace(props.to as string)} />
    </NavItem>
  );
};

const CampanhaWhatsappDetail: React.FC = () => {
  const { unidadeUrl } = useContext(UnidadeContext);
  const params = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<CampaignItem>();
  const socket = useWebsocket(unidadeUrl.replace(/^https(.*)$/, 'wss$1/vertiba/ws'));

  useEffect(() => {
    if (socket) {
      return socket?.listen('message', (evt: WebSocketMessageEvent) => {
        const json = JSON.parse(evt.data);

        if (json.event && json.event.success) {
          if (json.event.name === 'whatsapp_campaign') {
            if (json.event.key === 'status') {
              setCampaign((prev) => ({
                ...(prev || ({} as CampaignItem)),
                active: !!json.data.active,
              }));
            }
          }
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      return socket?.listen('open', () => {
        socket.send(
          JSON.stringify({
            event: 'whatsapp_campaign',
            key: 'status',
          }),
        );
      });
    }
  }, [socket]);

  useAfterUnidadeEffect(() => {
    fetchWhatsappCampaign(params.id)
      .then(({ data }) => setCampaign(data.data))
      .finally(() => setLoading(false));
  });

  return (
    <Page>
      <SectionLoading isLoading={isLoading}>
        <CampaignHeader>
          <ButtonBack path="/whatsapp-campaign" />
          <CampaignTitle>{campaign?.name}</CampaignTitle>
        </CampaignHeader>

        <NavTabs tabs>
          <TabItem exact to={`/whatsapp-campaign/${params.id}`}>
            Detalhes
          </TabItem>
          <TabItem to={`/whatsapp-campaign/${params.id}/accepted`}>Contatos aceitos</TabItem>
        </NavTabs>
        <TabContent className="pt-3 mb-3 bg-white border-top-0">
          <TabPane>
            <Switch>
              <Route
                exact
                path="/whatsapp-campaign/:id"
                component={(props: RouteComponentProps) => (
                  <TabCampanhaDetalhes {...props} socket={socket} campanha={campaign} />
                )}
              />
              <Route
                path="/whatsapp-campaign/:id/accepted"
                component={(props: RouteComponentProps) => <TabContatosAceitos {...props} campanha={campaign} />}
              />
            </Switch>
          </TabPane>
        </TabContent>
      </SectionLoading>
    </Page>
  );
};

export default CampanhaWhatsappDetail;
