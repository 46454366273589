import React from 'react';

// import { Col, Row } from 'reactstrap';
import FieldInput from '../../../components/FieldInput';
// import FieldSelectGroup from '../FieldSelectGroup';

const ModalForm: React.FC = () => {
  return (
    <>
      <FieldInput name="name" type="text" label="Nome de exibição" placeholder="Nome da campanha" />
      {/* <Row form>
        <Col>
          <FieldInput name="username" type="text" label="Usuário (login)" placeholder="username" />
        </Col>
        <Col>
          <FieldInput
            name="password"
            label="Senha (login)"
            type="password"
            placeholder="••••••"
            autocomplete="new-password"
          />
        </Col>
      </Row> */}
      {/* <Row form>
        <Col>
          <FieldInput name="fop2_user" type="text" label="Usuário operador" placeholder="username" />
        </Col>
        <Col>
          <FieldInput name="fop2_pass" label="Senha operador" type="password" placeholder="••••••" />
        </Col>
      </Row> */}
      {/* <FieldSelectGroup name="role" label="Grupo do usuário" /> */}
    </>
  );
};

export default ModalForm;
