import { createContext, useContext, useEffect } from 'react';

export interface UnidadeContextProps {
  loading: boolean;
  unidade?: Unidade;
  unidadeUrl: string;
}

export const UnidadeContext = createContext<UnidadeContextProps>({
  loading: false,
  unidadeUrl: '',
});

export function useAfterUnidadeEffect(callback: () => void): void {
  const { loading } = useContext(UnidadeContext);

  useEffect(() => {
    if (!loading) {
      callback();
    }
    // eslint-disable-next-line
  }, [loading]);
}
