import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 12px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div {
    margin-right: 8px;
    &:last {
      margin-right: 0;
    }
  }

  .form-group {
    flex: 1;
  }

  @media screen and (min-width: 768px) {
    .form-group {
      max-width: 180px;
    }
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > div {
    margin-right: 8px;
    &:last {
      margin-right: 0;
    }
  }
`;

export const FilterContainer = styled.div`
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;

export const FilterSearchContainer = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 220px;
    width: auto;
  }
`;

export const FilterRadioContainer = styled.div`
  margin-bottom: 0.5rem;

  .custom-radio {
    margin-bottom: -3px;
  }
`;
