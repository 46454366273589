import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Spinner, Table } from 'reactstrap';

import { serverApi } from '../../../services/api';
import { RelatorioEfetuadasFields } from '../FormRelatorioEfetuadas';
import RelatorioEfetuadasItem from '../RelatorioEfetuadasItem';

interface Props {
  query: RelatorioEfetuadasFields;
  onNewQuery: () => void;
}

// TODO: Filter columns and return true if success
function reportFilter(search: string) {
  return (item: any) => {
    const regexp = new RegExp(`.*${search}.*`, 'gi');
    return search === '' || regexp.test(item.src) || regexp.test(item.name.toLowerCase());
  };
}

const ListRelatorioEfetuadas: React.FC<Props> = (props) => {
  const { onNewQuery, query } = props;
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = {
      start_date: query.startDate,
      end_date: query.endDate,
      selected: query.selected.map((item) => item.id).join(),
    };

    serverApi
      .get('/vertiba-api/done-calls.list.php', { params })
      .then(({ data }) => setData(data.data))
      .finally(() => setLoading(false));
  }, [query]);

  return (
    <>
      <Row>
        <Col className="mb-3 mb-md-0">
          <Button color="primary" size="sm" onClick={onNewQuery}>
            Nova consulta
          </Button>
        </Col>
        <Col md={4}>
          <Input bsSize="sm" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
        </Col>
      </Row>
      <Table className="mt-5" size="sm" borderless responsive hover>
        <thead className="border-bottom">
          <tr>
            <th>#</th>
            <th>Chamador</th>
            <th className="text-center">Ligações</th>
            <th className="text-center">% Ligações</th>
            <th className="text-center">Duração Total</th>
            <th className="text-center">% Duração</th>
            <th className="text-center">Média de Duração</th>
          </tr>
        </thead>
        <tbody>
          {data?.filter(reportFilter(search)).map((item, index) => (
            <RelatorioEfetuadasItem key={index} {...item} index={index + 1} />
          ))}
        </tbody>
      </Table>
      <div className="pt-2 pb-3 d-flex justify-content-center">
        {loading ? <Spinner /> : data?.length === 0 && <p>Nenhum registro encontrado</p>}
      </div>
    </>
  );
};

export default ListRelatorioEfetuadas;
