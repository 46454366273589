import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { WebSocketKeepAlive } from '../../../utils';
import CampanhaMailing from '../CampanhaMailing';
import { CampaignItem } from '../utils';
import {
  CampanhaStatusContainer,
  CampanhaStatusSpinner,
  CampanhaStatusSpinnerIndicator,
  CampanhaStatusText,
  Container,
} from './styles';

interface Props extends RouteComponentProps {
  campanha?: CampaignItem;
  socket?: WebSocketKeepAlive;
}

const TabCampanhaDetalhes: React.FC<Props> = (props) => {
  const { campanha, socket } = props;
  const [active, setActive] = useState(campanha?.active);

  useEffect(() => {
    return socket?.listen('message', (evt: WebSocketMessageEvent) => {
      const json = JSON.parse(evt.data);

      if (json.event && json.event.success) {
        if (json.event.name === 'whatsapp_campaign') {
          if (json.event.key === 'start') {
            setActive(true);
          } else if (json.event.key === 'stop') {
            setActive(false);
          } else if (json.event.key === 'status') {
            setActive(json.data.active);
          }
        }
      }
    });
  }, [socket]);

  function toggleStatus() {
    socket?.send(
      JSON.stringify({
        event: 'whatsapp_campaign',
        key: active ? 'stop' : 'start',
      }),
    );
  }

  return (
    <Container>
      <CampanhaStatusContainer>
        <CampanhaStatusSpinner onClick={toggleStatus} className={active && 'active'}>
          <CampanhaStatusSpinnerIndicator />
        </CampanhaStatusSpinner>
        <CampanhaStatusText>A campanha está {active ? 'ativa' : 'inativa'}</CampanhaStatusText>
      </CampanhaStatusContainer>

      {campanha && <CampanhaMailing campanha={campanha} />}
    </Container>
  );
};

export default TabCampanhaDetalhes;
