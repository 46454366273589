import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArchive, faBars, faColumns, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BottomTabItem, Container, SVGIcon } from './styles';

interface Props {
  to: string;
  label: string;
  icon?: React.ReactNode;
}

const BottomTabButton: React.FC<Props> = ({ to, icon, label }) => {
  const history = useHistory();
  return (
    <BottomTabItem
      to={to}
      // Para fazer replace da rota, ao invés de gerar histórico no navegador
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        history.replace(to);
      }}
    >
      {icon}
      <span>{label}</span>
    </BottomTabItem>
  );
};

const FaIcon: React.FC<{ icon: IconProp }> = (props) => {
  return <FontAwesomeIcon icon={props.icon} style={{ width: 20, height: 20, marginBottom: 2 }} />;
};

const BottomTab: React.FC = () => {
  return (
    <Container>
      <BottomTabButton to="/operator-panel" label="Filas" icon={<FaIcon icon={faColumns} />} />
      <BottomTabButton to="/callcenter" label="CallCenter" icon={<FaIcon icon={faHeadset} />} />
      <BottomTabButton to="/phone" label="Telefone" icon={<SVGIcon src="/images/svg/phone-keyboard.svg" />} />
      <BottomTabButton to="/report" label="Relatórios" icon={<FaIcon icon={faArchive} />} />
      <BottomTabButton to="/menu" label="Menu" icon={<FaIcon icon={faBars} />} />
    </Container>
  );
};

export default BottomTab;
