import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

import { AuthUser } from '../../../services/auth';
import RouteNavItem from '../../RouteNavItem';

interface CallcenterRoute {
  label: string;
  menu: string;
  icon?: IconProp;
  permission?: boolean;
  items?: CallcenterRoute[];
}

function renderMenu(baseUrl: string, route: CallcenterRoute) {
  const url = [baseUrl, route.menu].join('/');
  const user = AuthUser.getInstance();

  return route.permission !== false ? (
    <RouteNavItem
      to={url}
      key={route.menu}
      icon={route.icon}
      label={route.label}
      hasPermission={user.hasPermission(route.menu)}
    >
      {route.items?.map((item) => renderMenu(url, item))}
    </RouteNavItem>
  ) : null;
}

const NavItemCallCenter: React.FC = () => {
  return renderMenu('/callcenter', {
    label: 'CallCenter',
    menu: 'rajada',
    icon: faHeadset,
    items: [
      { label: 'Painel do agente', menu: 'agent_console' },
      {
        label: 'Ligações de saída',
        menu: 'outgoing_calls',
        items: [
          { label: 'Campanha', menu: 'campaign_out' },
          { label: 'Lista não ligar', menu: 'dont_call_list' },
          { label: 'URLs Externa', menu: 'external_url' },
        ],
      },
      {
        label: 'Ligações de entrada',
        menu: 'ingoing_calls',
        items: [
          { label: 'Filas', menu: 'queues' },
          { label: 'Clientes', menu: 'client' },
          { label: 'Campanhas de Entrada', menu: 'campaign_in' },
        ],
      },
      {
        label: 'Opções de agente',
        menu: 'agentoptions',
        items: [
          { label: 'Agentes', menu: 'agents' },
          { label: 'Usuários ECCP', menu: 'eccp_users' },
          { label: 'Ramais Callback', menu: 'cb_extensions' },
        ],
      },
      { label: 'Pausas', menu: 'break_administrator' },
      {
        label: 'Formulários',
        menu: 'forms',
        items: [
          { label: 'Designer de formulários', menu: 'form_designer' },
          { label: 'Pré-visualização de formulário', menu: 'form_list' },
        ],
      },
      {
        label: 'Relatórios',
        menu: 'reports_ingoing_call',
        items: [
          { label: 'Relatórios de Pausa', menu: 'reports_break' },
          { label: 'Detalhe das Ligações', menu: 'calls_detail' },
          { label: 'Ligações por Hora', menu: 'calls_per_hour' },
          { label: 'Ligações por Agente', menu: 'calls_per_agent' },
          { label: 'Tempo de Espera', menu: 'hold_time' },
          { label: 'Entrada/Saída', menu: 'login_logout' },
          {
            label: 'Ligações Recebidas com Sucesso',
            menu: 'ingoings_calls_success',
          },
          { label: 'Gráfico de Ligações por Hora', menu: 'graphic_calls' },
          { label: 'Informação do Agente', menu: 'rep_agent_information' },
          { label: 'Monitoramento de Agentes', menu: 'rep_agents_monitoring' },
          {
            label: 'Troncos Usados por Hora',
            menu: 'rep_trunks_used_per_hour',
          },
          {
            label: 'Monitoramento de Ligações de Entrada',
            menu: 'rep_incoming_calls_monitoring',
          },
          { label: 'Monitoramento de Campanhas', menu: 'campaign_monitoring' },
        ],
      },
      { label: 'Configuração', menu: 'callcenter_config' },
    ],
  });
};

export default NavItemCallCenter;
