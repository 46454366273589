import React, { useEffect, useRef } from 'react';
import { CustomInput, CustomInputProps, FormGroup, Label } from 'reactstrap';

import { useField } from '@unform/core';

interface Props extends Omit<CustomInputProps, 'type'> {
  name: string;
  label?: string;
}

// type InputProps = JSX.IntrinsicElements["input"] & Props;

const FieldSwitch: React.FC<Props> = (props) => {
  const { name, label, placeholder, ...rest } = props;
  const { fieldName, defaultValue, registerField } = useField(name);
  const inputRef = useRef<CustomInput>(null);

  useEffect(() => {
    registerField<boolean>({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <FormGroup>
      {label && <Label for={fieldName}>{label}</Label>}
      <div>
        {
          <CustomInput
            type="switch"
            id={fieldName}
            innerRef={inputRef}
            label={placeholder}
            defaultChecked={defaultValue}
            {...rest}
          />
        }
      </div>
    </FormGroup>
  );
};

export default FieldSwitch;
