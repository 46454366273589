interface PhoneFormat {
  mask: string;
  types?: string[];
}

const formats: PhoneFormat[] = [
  {
    mask: '(0XX XX) 9XXXX-XXXX',
    types: ['mobile', 'state'],
  },
  {
    mask: '(0XX XX) XXXX-XXXX',
    types: ['landline', 'state'],
  },
  {
    mask: '(XX) 9XXXX-XXXX',
    types: ['mobile', 'city'],
  },
  {
    mask: '(XX) XXXX-XXXX',
    types: ['landline', 'city'],
  },
  {
    mask: '9XXXX-XXXX',
    types: ['mobile', 'local'],
  },
  {
    mask: 'XXXX-XXXX',
    types: ['landline', 'local'],
  },
];

export default class PhoneNumber {
  private phone: string;
  private phone_format?: PhoneFormat;

  constructor(phone: string) {
    this.phone = phone.replace(/^00/, '0');

    for (const format of formats) {
      // Tranform format digits to regexp
      const format_digits = format.mask.replace(/[^\dX]/g, '').replace(/X/g, '\\d');

      // Create regexp object with pattern
      const format_regexp = new RegExp(`${format_digits}$`);

      // If matches, assign this mask
      if (~this.phone.search(format_regexp)) {
        this.phone_format = format;
        break;
      }
    }
  }

  format() {
    if (this.phone_format) {
      return recursive_mask(this.phone_format.mask, this.phone);
    }

    return this.phone;
  }

  getMask() {
    return this.phone_format?.mask;
  }

  is(type: string) {
    return this.phone_format?.types?.includes(type);
  }
}

function recursive_mask(mask: string, phone: string): string {
  // If is last char finish it
  if (mask.length === 0) return '';

  // Get last char of phone and mask
  const mask_char = mask.slice(-1);
  const phone_char = phone.slice(-1);

  // If is digit or X replace it
  if (~mask_char.search(/[\dX]/)) {
    return recursive_mask(mask.slice(0, -1), phone.slice(0, -1)) + phone_char;
  } else {
    return recursive_mask(mask.slice(0, -1), phone) + mask_char;
  }
}
