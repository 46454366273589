import React, { useContext, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import querystring from 'querystring';

import { UnidadeContext } from '../../../services/context';

interface Props {
  search: string;
  filter: string;
  start_date: string;
  end_date: string;
}

const DownloadDropdown: React.FC<Props> = (props) => {
  const { search, filter, start_date, end_date } = props;
  const { unidadeUrl } = useContext(UnidadeContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const query = querystring.stringify({ search, filter, start_date, end_date });

  return (
    <Dropdown style={{ marginTop: 29 }} isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret>Exportar</DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          tag="a"
          target="_blank"
          children="Arquivo CSV"
          href={`${unidadeUrl}/vertiba-api/all-calls.list.php?${query}&export=csv`}
        />
        <DropdownItem
          tag="a"
          target="_blank"
          children="Excel XLS"
          href={`${unidadeUrl}/vertiba-api/all-calls.list.php?${query}&export=xls`}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default DownloadDropdown;
